import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import feathers, { Application } from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import { environment } from 'src/environments/environment';

@Injectable()
export class PublicFeathersService {
  private _app: Application;
  public get app(): Application {
    return this._app;
  }
  constructor(httpClient: HttpClient) {
    const options = {
      HttpHeaders,
      headers: { Authorization: `Basic ${btoa('qTL%RNMXcU33EaRMV^m!:pucW4#xRFupcmyK&n5n9')}` },
    };
    this._app = feathers();
    this._app.configure(restClient(environment.publicApiUrl).angularHttpClient(httpClient, options));
  }
}
