import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-schedule-dialog',
  template: `
    <h2 mat-dialog-title>Schedule Report</h2>
    <mat-dialog-content>
      <p>Wann soll der Report erstellt werden?</p>
      <form
        [formGroup]="scheduleForm"
        style="display: flex; flex-direction: column;"
      >
        <mat-form-field>
          <mat-label>Zeitpunkt</mat-label>
          <mat-select formControlName="interval">
            <mat-option value="daily">täglich</mat-option>
            <mat-option value="weekly">wöchentlich</mat-option>
            <mat-option value="monthly">monatlich</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="scheduleForm.controls['interval'].value === 'weekly'"
        >
          <mat-label>Wochentag</mat-label>
          <mat-select formControlName="dayOfWeek">
            <mat-option value="MONDAY">Montag</mat-option>
            <mat-option value="TUESDAY">Dinestag</mat-option>
            <mat-option value="WEDNESDAY">Mittwoch</mat-option>
            <mat-option value="THURSDAY">Donnerstag</mat-option>
            <mat-option value="FRIDAY">Freitag</mat-option>
            <mat-option value="SATURDAY">Samstag</mat-option>
            <mat-option value="SUNDAY">Sonntag</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="scheduleForm.controls['interval'].value === 'monthly'"
        >
          <mat-label>Tag</mat-label>
          <mat-select formControlName="dayOfMonth">
            <mat-option [value]="day" *ngFor="let day of days">{{
              day
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <div>Uhrzeit</div>
          <div
            style="display: flex; margin-top: 5px; justify-content: space-between;"
          >
            <mat-form-field style="margin-right: 15px;">
              <mat-label>Stunde</mat-label>
              <mat-select formControlName="timeHour">
                <mat-option [value]="hour" *ngFor="let hour of hours">{{
                  hour
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Minute</mat-label>
              <mat-select formControlName="timeMinute">
                <mat-option [value]="minute" *ngFor="let minute of minutes">{{
                  minute
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field>
          <mat-label>Nächster Lauf</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="nextRun"
            [min]="minNextRunDate"
            [matDatepickerFilter]="nextRunDateFilter"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Empfänger</mat-label>
          <input
            matInput
            type="email"
            formControlName="recipient"
            placeholder="sombody@example.com"
          />
          <mat-icon class="fa fa-email" matSuffix></mat-icon>
          <mat-error
            *ngIf="scheduleForm.controls['recipient'].hasError('email')"
          >
            Bitte geben Sie eine gültige E-Mail-Adresse ein.
          </mat-error>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions style="display: flex; justify-content: flex-end;">
      <button mat-button mat-dialog-close>Abbrechen</button>
      <button
        mat-button
        (click)="save()"
        color="primary"
        [disabled]="scheduleForm.invalid"
      >
        Speichern
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleDialogComponent implements OnInit {
  days = Array(31)
    .fill(0)
    .map((_value, index) => index + 1);
  hours = Array(24)
    .fill(0)
    .map((_value, index) => `${index < 10 ? '0' : ''}${index}`);
  minutes = Array(60)
    .fill(0)
    .map((_value, index) => `${index < 10 ? '0' : ''}${index}`);

  minNextRunDate = moment().add(1, 'day').toDate();
  scheduleForm = this._formBuilder.group({
    interval: ['daily', Validators.required],
    dayOfMonth: [''],
    dayOfWeek: [''],
    timeHour: ['00', Validators.required],
    timeMinute: ['00', Validators.required],
    nextRun: ['', Validators.required],
    recipient: ['', [Validators.required]],
  });
  nextRunDateFilter = (date: moment.Moment | null): boolean => {
    const interval = this.scheduleForm.get('interval')?.value;
    if (!date) {
      return false;
    }

    const day = moment(date).date();
    const weekday = date
      .toDate()
      .toLocaleString('en-us', { weekday: 'long' })
      .toUpperCase();

    if (interval === 'monthly') {
      const dayOfMonth = parseInt(
        this.scheduleForm.get('dayOfMonth')?.value,
        10
      );
      return day === dayOfMonth;
    } else if (interval === 'weekly') {
      const dayOfWeek = this.scheduleForm.get('dayOfWeek')?.value;
      return weekday === dayOfWeek;
    }

    return true; // Default to true if none of the conditions match or if daily.
  };
  constructor(
    private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.schedule) {
      const args = this.data.schedule.action.args[0];
      const nextRun = this.data.schedule.info.nextActionTimes[0];
      this.scheduleForm.patchValue({ ...args, nextRun });
    }
    this.scheduleForm.valueChanges.subscribe((value) => {
      switch (value.interval) {
        case 'monthly':
          this.scheduleForm.get('dayOfWeek').setValidators(null);
          this.scheduleForm
            .get('dayOfMonth')
            .setValidators(Validators.required);
          if (this.scheduleForm.get('dayOfMonth').value === '') {
            this.scheduleForm
              .get('dayOfMonth')
              .setValue(1, { emitEvent: false });
          }
          this.scheduleForm.get('dayOfWeek').setValue('', { emitEvent: false });
          break;
        case 'weekly':
          this.scheduleForm.get('dayOfMonth').setValidators(null);
          this.scheduleForm.get('dayOfWeek').setValidators(Validators.required);
          this.scheduleForm
            .get('dayOfMonth')
            .setValue('', { emitEvent: false });
          if (this.scheduleForm.get('dayOfWeek').value === '') {
            this.scheduleForm
              .get('dayOfWeek')
              .setValue('MONDAY', { emitEvent: false });
          }

          break;
        default:
          this.scheduleForm
            .get('dayOfMonth')
            .setValue('', { emitEvent: false });
          this.scheduleForm.get('dayOfWeek').setValue('', { emitEvent: false });
          this.scheduleForm.get('dayOfWeek').setValidators(null);
          this.scheduleForm.get('dayOfMonth').setValidators(null);
      }
      if (
        moment()
          .set('hour', value.timeHour)
          .set('minute', value.timeMinute)
          .isBefore(moment())
      ) {
        this.minNextRunDate = moment().add(1, 'day').toDate();
      } else {
        this.minNextRunDate = moment().toDate();
      }
    });
  }

  save() {
    const { value } = this.scheduleForm;
    value.nextRun = moment(value.nextRun)
      .set('hour', value.timeHour)
      .set('minute', value.timeMinute)
      .toISOString(true);
    this._dialogRef.close(value);
  }
}
