<mat-card>
  <mat-card-header
    ><div mat-card-avatar><mat-icon>security</mat-icon></div>
    <mat-card-title>Security</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <a
      mat-stroked-button
      class="full-width"
      color="primary"
      [routerLink]="['security', 'apikey']"
    >
      API-Keys anzeigen
    </a>
  </mat-card-content>
</mat-card>
