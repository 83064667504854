import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { DeleteDialogComponent } from './delete-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DeleteDialogService {
  private _afterDelConfirmSubject = new Subject();
  afterDelConfirm = this._afterDelConfirmSubject.asObservable();

  constructor(private _dialog: MatDialog) {}

  show(entityName: string, entityTitle: string, confirmWithInput = true) {
    const subscription = this._dialog
      .open(DeleteDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { entityName, entityTitle, confirmWithInput },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this._afterDelConfirmSubject.next(result);
        }
        subscription.unsubscribe();
      });
  }
}
