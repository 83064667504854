import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/shared/services/auth.service';

import { User } from '../../shared/models/user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  @Input()
  user: User;
  @Input()
  sidenav: MatSidenav;
  @Output()
  updated = new EventEmitter();
  showSpinner: boolean;

  constructor(private _userService: UserService, private _auth: AuthService) {}

  ngOnInit() {}

  async save() {
    let updatedUser: User;
    if (this.user._id) {
      const { _id, selected, timestamp, tenantId, email, ...rest } = this.user as any;
      updatedUser = (await this._userService.patch(_id, rest)) as User;
    } else {
      this.user.tenantId = this._auth.currentUser.tenant._id;
      updatedUser = (await this._userService.create(this.user as any)) as User;
    }
    this.sidenav.close();
    this.updated.emit(updatedUser);
  }
}
