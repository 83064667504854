import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-credits-tile',
  templateUrl: './credits-tile.component.html',
  styleUrls: ['./credits-tile.component.scss'],
})
export class CreditsTileComponent implements OnInit {
  @Input() credits: number;
  @Input() isAdmin: boolean;

  constructor() {}

  ngOnInit() {}
}
