import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AttachmentBinaryService } from 'src/app/shared/services/attachment-binary.service';
import { AttachmentService } from 'src/app/shared/services/attachment.service';
import { TreeArray } from 'src/app/shared/tree-array';

@Injectable({
  providedIn: 'root',
})
export class AuditReportService {
  constructor(
    private _attachmentService: AttachmentService,
    private _attachmentBinaryService: AttachmentBinaryService
  ) {}

  public async prepareDataForInfoModal(audit) {
    const assets = await this.getAssets(audit);
    audit.auditNodes = await Promise.all(
      audit.auditNodes.map((section, index, array) =>
        this.prepareData(section, undefined, array, assets)
      )
    );
    return audit;
  }

  public async prepareDataForReport(audit) {
    const assets = await this.getAssets(audit);
    const reportData = {
      Sections: [],
    };
    if (audit && audit.auditNodes && audit.auditNodes.length > 0) {
      await Promise.all(
        audit.auditNodes.map(async (section, index, array) => {
          reportData.Sections.push({
            SectionId: section.id,
            SectionName: section.title,
          });
          const element = {};
          await this.prepareData(section, element, array, assets);
          reportData[section.title] = [element];
        })
      );
    }
    return reportData;
  }

  public async prepareDataForExport(audit) {
    const assets = await this.getAssets(audit);
    const reportData = {};
    if (audit && audit.auditNodes && audit.auditNodes.length > 0) {
      await Promise.all(
        audit.auditNodes.map(async (section, index, array) => {
          const element = {};
          await this.prepareData(section, element, array, assets);
          for (const [key, value] of Object.entries(element)) {
            if (
              typeof value === 'object' &&
              !Array.isArray(value) &&
              value !== null
            ) {
              let result: string[] = [];
              Object.entries(value).forEach(([key, value], index) => {
                result.push(`${key}: ${value}`);
              });

              const formattedString: string = result.join('<br/>');
              element[key] = formattedString;
            }
          }
          Object.assign(reportData, element);
        })
      );
    }
    return reportData;
  }

  private getAssets(audit) {
    return this._attachmentService.find({
      query: {
        $or: [
          { 'metadata.auditId': audit._id },
          { 'metadata.templateId': audit.templateId },
        ],
      },
    });
  }

  private async prepareData(section, element = {}, auditNodes, assets) {
    this.evalConditions(section, auditNodes);
    const filteredChildren = section.children.filter(
      (node) => node.nodeType_Id !== 'condition'
    );
    await this.mapAssets(filteredChildren, assets);
    const children = filteredChildren.map((node) => {
      if (node.title) {
        element[node.title] = '';
      }
      let selected;
      switch (node.nodeType_Id) {
        case 'date':
          if (node.responses && node.responses.length > 0) {
            element[node.title] = node.responses[0].value
              ? this.formatDateWithOptions(
                  node.responses[0].value,
                  node.options
                )
              : '';
            // element[node.title] = node.responses[0].value
            //   ? node.responses[0].value
            //   : '';
          }
          break;
        case 'location':
          if (node.responses && node.responses.length > 0) {
            element[node.title] = node.responses[0].value
              ? node.responses[0].value
              : '';
          }
          break;
        case 'image':
        case 'signature':
          if (node.responses && node.responses.length > 0) {
            element[node.title] = node.responses
              .sort((a: any, b: any) =>
                a.item && a.item.uploadDate && b.item && b.item.uploadDate
                  ? new Date(b.item.uploadDate).valueOf() -
                    new Date(a.item.uploadDate).valueOf()
                  : 1
              )
              .map((image) => ({ Image: image.data }));
          }
          break;
        case 'information':
          delete element[node.title];
          break;
        case 'checkbox':
          if (
            node.selectables &&
            node.selectables.length > 0 &&
            node.selectables[0].selected
          ) {
            element[node.title] = node.selectables[0].selected;
          }
          break;
        case 'multiplechoice':
          if (node.selectables && node.selectables.length > 0) {
            selected = node.selectables.filter((f) => f.selected);
            if (selected && selected.length > 0) {
              let value = '';
              selected.forEach((s) => {
                value += `${s.label}\n`;
              });
              element[node.title] = value;
            }
          }
          break;
        case 'dropdown':
        case 'text':
        case 'number':
        case 'textarea':
          if (node.responses && node.responses.length > 0) {
            element[node.title] = node.responses[0].value
              ? node.responses[0].value
              : '';
          }
          break;
        case 'inventory':
          if (node.responses && node.responses.length > 0) {
            element[node.title] = node.responses[0].selectedItem
              ? node.responses[0].selectedItem
              : '';
          }
          break;
        // case 'repeater':
        // case 'repeater_header':
        //     break;
        case 'contact':
          if (node.responses && node.responses.length > 0) {
            let contact = {
              name: true,
              organization: true,
              function: true,
              street: true,
              zip: true,
              city: true,
              phone: true,
              fax: true,
              mobile: true,
              email: true,
              website: true,
            };
            if (node.options && node.options.contact) {
              contact = node.options.contact;
            }
            let text = '';
            let i = 0;
            for (const e in contact) {
              if (contact.hasOwnProperty(e)) {
                if (e && node.responses[i].value !== '') {
                  text += `${node.responses[i].value}\n`;
                }
                i++;
              }
            }
            element[node.title] = text;
          }
          break;
        // case 'formula':
        //   if (node.selectables && node.selectables.length > 0) {
        //     element[node.title] = this._auditFormulaProvider.evalFormula(node.selectables, auditNodes);
        //   }
        //   break;
        default:
          if (node.selectables && node.selectables.length > 0) {
            selected = node.selectables.filter((s) => s.selected);
            if (selected && selected.length > 0) {
              element[node.title] = selected[0].label;
            }
            if (
              node.responses &&
              node.responses.length > 0 &&
              node.responses[0].value
            ) {
              element[node.title] += `\n${node.responses[0].value}`;
            }
          } else if (
            node.responses &&
            node.responses.length > 0 &&
            node.responses[0].value
          ) {
            element[node.title] = node.responses[0].value;
          }
      }
      return {
        title: node.title,
        value: element[node.title],
        nodeType_Id: node.nodeType_Id,
      };
    });
    return { title: section.title, children };
  }

  private formatDateWithOptions(date, options) {
    let _options = {
      date: true,
      time: true,
    };
    let format = '';
    if (options) {
      _options = options;
    }
    if (_options.date) {
      format += 'DD.MM.YYYY';
    }
    if (_options.time) {
      if (format !== '') {
        format += ', ';
      }
      format += 'HH:mm [Uhr]';
    }
    if (date && date !== '') {
      return moment.utc(date).local().format(format);
    }
  }

  private evalConditions(section, auditNodes) {
    section.children.forEach((node) => {
      if (node.nodeType_Id !== 'condition') {
        return;
      }
      const trigger = section.children.find(
        (f) => f.id === node.conditionTriggerId
      );
      if (trigger) {
        section.children
          .filter((f) => f.conditionId === node.id)
          .map((m) => {
            if (
              (trigger.selectables &&
                trigger.selectables.length > 0 &&
                trigger.nodeType_Id !== 'dropdown') ||
              trigger.nodeType_Id === 'checkbox'
            ) {
              // m.inactive =
              // trigger.nodeType_Id === 'formula'
              //   ? !this._auditConditionProvider.conditionEval(
              //       node.selectables,
              //       this._auditFormulaProvider.evalFormula(trigger.selectables, auditNodes),
              //     )
              //   : !this._auditConditionProvider.conditionEval(node.selectables, trigger.selectables);
            } else {
              // m.inactive = !this._auditConditionProvider.conditionEval(
              //   node.selectables,
              //   trigger.responses && trigger.responses.length > 0 ? trigger.responses[0].value : undefined,
              // );
            }
          });
      }
    });
  }

  private async mapAssets(auditNodes, assets) {
    if (!assets) {
      return;
    }

    for (const item of assets) {
      const imageData: any = await this._attachmentBinaryService.get(
        item._id,
        item.contentType
      );
      const node = item.metadata.templateNodeId
        ? TreeArray.findRec(
            auditNodes,
            (n) => n.templateNodeId === item.metadata.templateNodeId
          )
        : TreeArray.findRec(
            auditNodes,
            (n) => n.id === item.metadata.auditNodeId
          );
      if (!node) {
        continue;
      }
      node.responses = node.responses || [];
      node.responses.push({ data: imageData });
    }
  }
}
