import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SwodocNodeCheckboxComponent } from 'src/app/audit/nodes/checkbox/swodoc-node-checkbox';
import { SwodocNodeDropdownComponent } from 'src/app/audit/nodes/dropdown/swodoc-node-dropdown';
import { SwodocNodeFormulaComponent } from 'src/app/audit/nodes/formula/swodoc-node-formula';
import { SwodocNodeInformationComponent } from 'src/app/audit/nodes/information/swodoc-node-information';
import { SwodocNodeMultiplechoiceComponent } from 'src/app/audit/nodes/multiplechoice/swodoc-node-multiplechoice';
import { SwodocNodeNumberComponent } from 'src/app/audit/nodes/number/swodoc-node-number';
import { SwodocNodePictureComponent } from 'src/app/audit/nodes/picture/swodoc-node-picture';
import { SwodocNodeSignatureComponent } from 'src/app/audit/nodes/signature/swodoc-node-signature';
import { SwodocNodeDateComponent } from './date/swodoc-node-date';
import { SwodocNodeInventoryComponent } from './inventory/swodoc-node-inventory';
import { SwodocNodeInventoryModule } from './inventory/swodoc-node-inventory.module';
import { SwodocNodeQuestionComponent } from './question/swodoc-node-question';
import { SwodocNodeTextComponent } from './text/swodoc-node-text';
import { SwodocNodeTextareaComponent } from './textarea/swodoc-node-textarea';

@NgModule({
  declarations: [
    SwodocNodeTextComponent,
    SwodocNodeDateComponent,
    SwodocNodeCheckboxComponent,
    SwodocNodeDropdownComponent,
    SwodocNodeInformationComponent,
    SwodocNodeQuestionComponent,
    SwodocNodeTextareaComponent,
    SwodocNodeNumberComponent,
    SwodocNodeMultiplechoiceComponent,
    SwodocNodePictureComponent,
    SwodocNodeFormulaComponent,
    SwodocNodeSignatureComponent,
    // SwodocNodeImage,
    // SwodocNodeLocation,
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    SwodocNodeInventoryModule,
    NgxMaterialTimepickerModule.setLocale('de-DE'),
    SignaturePadModule,
  ],
  exports: [
    SwodocNodeTextComponent,
    SwodocNodeDateComponent,
    SwodocNodeCheckboxComponent,
    SwodocNodeDropdownComponent,
    SwodocNodeInformationComponent,
    SwodocNodeQuestionComponent,
    SwodocNodeTextareaComponent,
    SwodocNodeNumberComponent,
    SwodocNodeMultiplechoiceComponent,
    SwodocNodePictureComponent,
    SwodocNodeFormulaComponent,
    SwodocNodeInventoryComponent,
    SwodocNodeSignatureComponent,
    // SwodocNodeImage,
    // SwodocNodeLocation,
  ],
})
export class SwodocNodesModule {}
