import { MongoModel } from 'src/app/shared/models/_mongo.model';
import { AuditNode } from 'src/app/shared/models/audit-node.model';

export class Audit extends MongoModel {
  templateId: string;
  displayType = 0;

  auditNodes: AuditNode[] = [];

  createdUtc: string;
  updatedUtc: string;

  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  archived: boolean;
}
