import { MongoModel } from './_mongo.model';
import { TemplateCategory } from './enums/template-category.enum';

export class Template extends MongoModel {
  title: string;
  description: string;
  category: TemplateCategory;
  draft?: boolean;
  shared?: { maxDate?: Date; maxCount?: number; url?: string };
  publicId: string;
  headerImageUrl: string;
  scheduledReportId: string;
  // TODO: add other fields

  constructor() {
    super();
    this.draft = false;
  }
}
