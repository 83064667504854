import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

enum NodeDateType {
  DateTime,
  Date,
  Time,
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-date',
  templateUrl: 'swodoc-node-date.html',
  styleUrls: ['swodoc-node-date.scss'],
})
export class SwodocNodeDateComponent implements OnInit {
  @Input()
  public node: any;
  public isDesktop: boolean;
  public displayFormat = 'DD.MM.YYYY HH:mm';
  public defaultTime = moment(moment())
    .add(15 - (moment().minute() % 15), 'minutes')
    .format('HH:mm');
  private _type: NodeDateType = NodeDateType.DateTime;
  public swodocTheme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: '#1d9c92',
    },
    dial: {
      dialBackgroundColor: '#1d9c92',
      dialEditableActiveColor: '#1d9c92',
    },
    clockFace: {
      clockHandColor: '#1d9c92',
      clockFaceTimeInactiveColor: '#1d9c92',
    },
  };
  public get maxDate(): string {
    return new Date(new Date().getFullYear() + 20, 11, 31).toISOString();
  }

  public get displayValueTime(): string {
    if (!this._displayValue) {
      return;
    }
    const timeString = this._displayValue.local().format('HH:mm');
    return timeString !== '00:00' ? timeString : '';
  }

  public set displayValueTime(time: string) {
    if (time) {
      const newDate = moment(
        `${
          this._displayValue
            ? this._displayValue.format('DD.MM.YYYY')
            : '01.01.1970'
        } ${time}`,
        'DD.MM.YYYY HH:mm'
      );
      this.displayValue = newDate;
    }
  }

  private _displayValue: moment.Moment;
  public get displayValue(): moment.Moment {
    return this._displayValue;
  }
  public set displayValue(value: moment.Moment) {
    this._displayValue = value;
    let _value;

    switch (this._type) {
      case NodeDateType.Date:
        _value = value.format();
        break;
      case NodeDateType.DateTime:
        _value = value.utc().format();
        break;
      case NodeDateType.Time:
        _value = moment(`01.01.1970 ${value}`, 'DD.MM.YYYY HH:mm')
          .utc()
          .format();
        break;
      default:
    }

    if (!this.node.responses || this.node.responses.length === 0) {
      this.node.responses[0] = { value: _value };
    } else {
      this.node.responses[0].value = _value;
    }
    this.changed.emit(this.node);
  }

  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(deviceDetectorService: DeviceDetectorService) {
    this.isDesktop = deviceDetectorService.isDesktop();
  }

  ngOnInit(): void {
    if (this.node.options) {
      if (this.node.options.date && this.node.options.time) {
        this.displayFormat = 'DD.MM.YYYY HH:mm';
        this._type = NodeDateType.DateTime;
      }
      if (this.node.options.date && !this.node.options.time) {
        this.displayFormat = 'DD.MM.YYYY';
        this._type = NodeDateType.Date;
      }
      if (!this.node.options.date && this.node.options.time) {
        this.displayFormat = 'HH:mm';
        this._type = NodeDateType.Time;
      }
    }
    if (
      this.node &&
      this.node.responses &&
      this.node.responses.length > 0 &&
      this.node.responses[0].value
    ) {
      if (this._type === NodeDateType.Date) {
        this._displayValue = moment(this.node.responses[0].value);
      } else {
        this._displayValue = moment(this.node.responses[0].value).utc().local();
      }
    }
  }
}
