import { Injectable } from '@angular/core';
import { PublicFeathersService } from 'src/app/public/services/public-feathers.service';
import { Attachment } from 'src/app/shared/models/attachment';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable()
export class PublicAttachmentService extends BaseService<Attachment> {
  constructor(publicFeathers: PublicFeathersService) {
    super('public/attachment', publicFeathers.app);
  }
}
