import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as tinycolor from 'tinycolor2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-question',
  templateUrl: 'swodoc-node-question.html',
  styleUrls: ['swodoc-node-question.scss'],
})
export class SwodocNodeQuestionComponent implements OnInit {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();
  showNote = false;

  get note(): string {
    if (this.node.responses && this.node.responses.length > 0 && this.node.responses[0].value) {
      return this.node.responses[0].value;
    }

    return '';
  }
  set note(value: string) {
    this.node.responses = this.node.responses || [];
    if (this.node.responses.length === 0) {
      this.node.responses.push({ value });
    } else {
      this.node.responses[0] = { value };
    }
    this.changed.emit(this.node);
  }

  ngOnInit(): void {
    this.node.selectables.map(button => {
      const color = tinycolor(button.color);
      color.setAlpha(0.4);
      button.bgColor = color.toRgbString();
      if (button.selected) {
        button.backgroundColor = button.bgColor;
        button.borderColor = button.color;
      }
    });
  }

  toggle(index) {
    const wasSelected = this.node.selectables[index].selected === 1;
    this.node.selectables.forEach(button => {
      button.selected = 0;
      button.backgroundColor = '';
      button.borderColor = '';
    });
    if (!wasSelected) {
      this.node.selectables[index].selected = 1;
      this.node.selectables[index].backgroundColor = this.node.selectables[index].bgColor;
      this.node.selectables[index].borderColor = this.node.selectables[index].color;
    }
    if (this.node.failedResponse === this.node.selectables[index].value && this.node.selectables[index].selected) {
      this.showNote = true;
    } else if (this.showNote) {
      this.showNote = false;
    }
    this.changed.emit(this.node);
  }
}
