import { Injectable } from '@angular/core';
import { PublicFeathersService } from 'src/app/public/services/public-feathers.service';
import { Inventory } from 'src/app/shared/models/inventory.model';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable()
export class PublicInventoryService extends BaseService<Inventory> {
  constructor(publicFeathers: PublicFeathersService) {
    super('public/inventory', publicFeathers.app);
  }
}
