import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AuditNode } from 'src/app/shared/models/audit-node.model';
import { Audit } from 'src/app/shared/models/audit.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { v4 } from 'uuid';

@Injectable()
export class CreateAuditService {
  constructor(private _auth: AuthService) {}

  public createAudit(template, prefillData = []) {
    const account = this._auth.currentUser;
    const copyNewAuditNodeFromTemplateNode = (
      templateNode: any,
      parentId: string
    ) => {
      const auditNode = new AuditNode();
      auditNode.id = v4();
      auditNode._id = auditNode.id;
      auditNode.archived = false;
      auditNode.parentId = parentId;

      if (templateNode.isSystemNode) {
        switch (templateNode.systemNode) {
          case 1:
            auditNode.responses = [{ value: getDocumentNo() }];
            break;
          case 4:
            auditNode.responses = [{ value: moment.utc().toISOString() }];
            break;
          case 5:
            auditNode.responses = [
              {
                value: account ? `${account.firstName} ${account.lastName}` : ''
              }
            ];
            break;
        }
      }
      auditNode.color = templateNode.color;
      auditNode.description = templateNode.description;
      auditNode.mandatory = templateNode.mandatory;
      auditNode.nodeType_Id = templateNode.nodeType_Id;
      auditNode.options = templateNode.options;
      auditNode.isSystemNode = templateNode.isSystemNode;
      auditNode.systemNode = templateNode.systemNode;
      auditNode.failedResponse = templateNode.failedResponse;
      auditNode.templateNodeId = templateNode._id;
      auditNode.selectables = templateNode.selectables || [];
      auditNode.title = templateNode.title;
      auditNode.unit = templateNode.unit;

      const data = prefillData.find(f => f._id === templateNode._id);
      if (data) {
        auditNode.responses = [{ value: data.value }];
      }

      return auditNode;
    };

    const audit = new Audit();
    audit._id = v4();
    audit.archived = false;
    audit.templateId = template._id;
    audit.displayType = template.defaultAuditDisplayType;
    audit.auditNodes = [];
    audit.createdAt = moment.utc().toDate();
    audit.updatedAt = moment.utc().toDate();
    if (account) {
      audit.createdBy = account._id;
      audit.updatedBy = account._id;
    }

    const numberFormat = template.documentNoFormat;

    copyNewAuditNodeFromTemplateNodeRec(
      template.templateNodes,
      audit.auditNodes
    );

    audit.auditNodes.forEach(auditNode => {
      if (auditNode.children) {
        auditNode.children = flatten(auditNode.children);
        auditNode.children.forEach(childAuditNode => {
          switch (childAuditNode.nodeType_Id) {
            case 'condition':
              childAuditNode.conditionTriggerId = childAuditNode.parentId;
              flatten(
                childAuditNode.children ? childAuditNode.children : []
              ).forEach(subNode => {
                subNode.conditionId = childAuditNode.id;
              });
              break;
            case 'repeater':
              flatten(
                childAuditNode.children ? childAuditNode.children : []
              ).forEach(subNode => {
                subNode.repeaterId = childAuditNode.id;
              });
              break;
            default:
          }
          if (childAuditNode.nodeType_Id !== 'repeater') {
            delete childAuditNode.children;
          }
        });
      }
    });
    template.documentNo++;

    function flatten(data) {
      const result = [];
      function flattenRec(_data, _result) {
        _data.forEach(element => {
          if (!_result.find(f => f.id === element.id)) {
            _result.push(element);
          }
          if (element.nodeType_Id === 'repeater') {
            flattenRec(
              element.children ? element.children : [],
              element.children
            );
          } else {
            flattenRec(element.children ? element.children : [], _result);
          }
        });
      }
      flattenRec(data, result);
      return result;
    }

    function getDocumentNo(): string {
      (Number.prototype as any).pad = function(size) {
        let s = String(this);
        while (s.length < (size || 2)) {
          s = `0${s}`;
        }
        return s;
      };
      if (!numberFormat || numberFormat === null) {
        return template.documentNo.pad(5);
      }

      if (numberFormat.indexOf('[number') === -1) {
        return numberFormat;
      }
      if (numberFormat.indexOf('[number]') !== -1) {
        return numberFormat.replace('[number]', template.documentNo.pad(5));
      }
      if (numberFormat.indexOf('[number=') !== -1) {
        const r1 = numberFormat.match(/\[(.*?)\]/);
        const d = parseInt(r1[1].match(/\=(.*?)$/)[1].replace(/\D/g, ''), 10);
        if (d && !isNaN(d)) {
          return numberFormat.replace(
            r1[0],
            template.documentNo.toLocaleString('en-US', {
              minimumIntegerDigits: d,
              useGrouping: false
            })
          );
        }
        return numberFormat.replace('[number]', template.documentNo.pad(5));
      }
      return template.documentNo.pad(5);
    }

    function copyNewAuditNodeFromTemplateNodeRec(
      templateNodes,
      auditNodes,
      parentId?: string
    ) {
      templateNodes.forEach(templateNode => {
        const auditNode = copyNewAuditNodeFromTemplateNode(
          templateNode,
          parentId
        );
        if (templateNode.children) {
          copyNewAuditNodeFromTemplateNodeRec(
            templateNode.children,
            auditNode.children,
            auditNode.id
          );
        }
        auditNodes.push(auditNode);
      });
    }

    return Promise.resolve(audit);
  }
}
