<div class="group-detail">
  <mat-toolbar color="primary" class="mat-elevation-z3">
    <mat-icon>people</mat-icon>
    <h1 *ngIf="group.label">{{group.label}}</h1>
    <h1 *ngIf="!group.label">[ Neu ]</h1>
  </mat-toolbar>
  <div class="mat-elevation-z1 top-button-bar">
    <div class="buttons">
      <button mat-button color="primary" (click)="save()">
        <mat-icon>save</mat-icon>Speichern</button>
      <button mat-button color="accent" (click)="sidenav.close()">
        <mat-icon>cancel</mat-icon>Abbrechen</button>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="content">
      <mat-form-field appearance="standard">
        <mat-label>Label</mat-label>
        <input matInput placeholder="Label" [(ngModel)]="group.label">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Region" aria-label="Region" [matAutocomplete]="regionAutocomplete" [formControl]="regionCtrl">
        <mat-autocomplete #regionAutocomplete="matAutocomplete" [displayWith]="displayRegionFct">
          <mat-option *ngFor="let region of allRegions$ | async" [value]="region">
            {{region.label}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div style="display:flex;">
        <h2 style="flex: 1;">Rechte</h2>
      </div>
      <mat-form-field>
        <input matInput placeholder="Recht hinzufügen" aria-label="Recht hinzufügen" [matAutocomplete]="rightsetAutocomplete" [formControl]="rightsetCtrl">
        <mat-autocomplete #rightsetAutocomplete="matAutocomplete" [displayWith]="displayRightsetFct">
          <mat-option *ngFor="let rightset of allRightsets$ | async" [value]="rightset" [disabled]="group?.rightSet?.includes(rightset._id)">
            {{rightset?.description}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <app-spinner *ngIf="showSpinner"></app-spinner>
      <div class="rightset-wrapper" *ngFor="let rightset of rightsets;let first=first;let last = last; let i = index">
        <div class="rightset">
          <div class="label">
            {{rightset.description}}
          </div>
          <button mat-icon-button (click)="removeRightset(rightset)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button [disabled]="first" (click)="moveRightsetUp(i, rightset)" disableRipple>
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button mat-icon-button [disabled]="last" (click)="moveRightsetDown(i, rightset)" disableRipple>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</div>
