<p class="title">{{ node.title }}</p>
<div class="wrapper" #wrapper>
  <signature-pad
    [options]="signaturePadOptions"
    (onEndEvent)="drawComplete()"
  ></signature-pad>
</div>
<button mat-button color="primary" (click)="clear()">
  <mat-icon>clear</mat-icon> Clear
</button>
