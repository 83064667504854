import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Copy } from '../models/copy.model';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  private _baseService: BaseService<Copy>;

  constructor(feathersService: FeathersService) {
    this._baseService = new BaseService('copy', feathersService.app);
  }

  copy(item: Copy) {
    return this._baseService.create(item);
  }
}
