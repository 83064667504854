import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EventService } from 'src/app/shared/services/event.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyResolver implements Resolve<any> {
  constructor(private _authService: AuthService, private _eventService: EventService, private _router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    try {
      const { hash } = route.params;
      if (hash) {
        this._authService.verify(hash).then(() => {
          this._eventService.dispatchEvent({ message: 'Email erfolgreich bestätigt' });
        });
      }
      this._router.navigateByUrl('/');
    } catch (e) {}
  }
}
