import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Template } from '../../shared/models/template.model';

@Component({
  selector: 'app-template-basic-info-form',
  templateUrl: './template-basic-info-form.component.html',
  styleUrls: ['./template-basic-info-form.component.scss'],
})
export class TemplateBasicInfoFormComponent implements OnInit, OnDestroy {
  @Input()
  template: Template;
  @Output()
  templateChange = new EventEmitter<Template>();
  @Output()
  valid = new EventEmitter<boolean>();

  createTemplateForm: FormGroup;
  private _createTemplateFormValueChangeSubscription: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createTemplateForm = this.fb.group({
      title: [this.template.title, Validators.required],
      description: [this.template.description],
      category: [this.template.category, Validators.required],
      draft: [this.template.draft],
    });
    if (this.template._id) {
      this.valid.emit(this.createTemplateForm.valid);
    }
    this._createTemplateFormValueChangeSubscription = this.createTemplateForm.valueChanges.subscribe(value => {
      this.valid.emit(this.createTemplateForm.valid);
      this.templateChange.emit(Object.assign(this.template, value));
    });
  }
  ngOnDestroy() {
    this._createTemplateFormValueChangeSubscription.unsubscribe();
  }
}
