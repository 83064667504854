export class TreeArray {
  public static filterRec(
    array: Array<any>,
    predicate,
    childElement: string = 'children'
  ) {
    let found = array.filter(predicate);
    for (const element of array) {
      if (element[childElement] instanceof Array) {
        found = [
          ...found,
          ...TreeArray.filterRec(element[childElement], predicate)
        ];
      }
    }
    return found;
  }
  public static findParentRec(
    array: Array<any>,
    predicate,
    childElement: string = 'children',
    prevElement
  ) {
    const found = array.find(predicate);
    if (found) {
      return prevElement;
    }
    for (const element of array) {
      if (element[childElement] instanceof Array) {
        const res = TreeArray.findParentRec(
          element[childElement],
          predicate,
          childElement,
          element
        );
        if (res) {
          return res;
        }
      }
    }
  }

  public static findRec(
    array: Array<any>,
    predicate,
    childElement: string = 'children'
  ) {
    const found = array.find(predicate);
    if (found) {
      return found;
    }
    for (const element of array) {
      if (element[childElement] instanceof Array) {
        const res = TreeArray.findRec(element[childElement], predicate);
        if (res) {
          return res;
        }
      }
    }
  }
}
