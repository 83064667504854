import { Injectable } from '@angular/core';
import { CustomerRegistrationParams } from 'src/app/shared/models/customer-registration-params.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerRegistrationGeneratorService extends BaseService<
  CustomerRegistrationParams
> {
  constructor(feathers: FeathersService) {
    super('customer-registration-generator', feathers.app);
  }
}
