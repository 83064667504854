import { Selectable } from 'src/app/shared/models/selectable.model';

export class AuditNode {
  isSystemNode: any;
  id: string;
  _id: string;
  archived: boolean;
  timestamp: Date;
  responses: { value: string }[] = [];
  color: any;
  description: any;
  mandatory: any;
  nodeType_Id: any;
  options: any;
  parentId: any;
  systemNode: any;
  failedResponse: any;
  selectables: Selectable[] = [];
  title: any;
  unit: any;
  children: AuditNode[] = [];
  conditionTriggerId: any;
  conditionId: any;
  templateNodeId: any;
  inactive: boolean;
}
