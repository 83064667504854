import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
})
export class ItemSelectorComponent implements OnInit {
  @Input() selected: boolean;
  @Input() color: string;
  @Output() selectedChange = new EventEmitter();

  constructor(private _renderer2: Renderer2) {}

  ngOnInit() {}

  mouseentercheck(event: Event) {
    if (!this.selected) {
      this._renderer2.removeClass(event.target, 'fa-circle');
      this._renderer2.addClass(event.target, 'fa-check-circle');
    }
  }

  mouseleavecheck(event: Event) {
    if (!this.selected) {
      this._renderer2.removeClass(event.target, 'fa-check-circle');
      this._renderer2.addClass(event.target, 'fa-circle');
    }
  }

  toggleTenantSelect(event: Event) {
    event.stopPropagation();
    this.selected = !this.selected;
    this.selectedChange.emit(this.selected);
    if (this.selected) {
      this._renderer2.removeClass(event.target, 'far');
      this._renderer2.addClass(event.target, 'fas');
      this._renderer2.addClass(event.target, 'selected');
    } else {
      this._renderer2.removeClass(event.target, 'fas');
      this._renderer2.removeClass(event.target, 'selected');
      this._renderer2.addClass(event.target, 'far');
    }
  }
}
