import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/home/home.component';
import { VerifyResolver } from 'src/app/home/verify.resolver';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';

const homeRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'verify/:hash',
    component: HomeComponent,
    canActivate: [AuthGuard],
    resolve: { verify: VerifyResolver },
  },
]);

@NgModule({
  imports: [homeRouting, MatCardModule, SharedModule],
  entryComponents: [],
  declarations: [HomeComponent],
  exports: [],
  providers: [],
})
export class HomeModule {}
