import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleReportService extends BaseService<any> {
  constructor(feathers: FeathersService) {
    super('schedule-report', feathers.app);
  }
}
