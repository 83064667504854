<div
  ng2FileDrop
  [ngClass]="{ 'file-over': hasBaseDropZoneOver }"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader"
  class="well drop-zone"
  *ngIf="uploader.queue.length === 0"
  (onFileDrop)="dropped($event)"
>
  <mat-icon>arrow_drop_down</mat-icon>
  <div class="drop-text">Dateien hier ablegen</div>
</div>

<app-spinner *ngIf="showSpinner"></app-spinner>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z5"
  [style.display]="(dataSource | async) ? '' : 'none'"
>
  <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
    <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
    <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
  </ng-container>

  <ng-container *ngIf="columnsToDisplay">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </ng-container>
</table>
