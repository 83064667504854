import { Injectable } from '@angular/core';
import { Attachment } from 'src/app/shared/models/attachment';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService extends BaseService<Attachment> {
  constructor(feathers: FeathersService) {
    super('attachment', feathers.app);
  }
}
