<mat-card class="login-card">
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form class="login-form" (submit)="login()">
      <mat-form-field hideRequiredMarker appearance="fill">
        <mat-icon matPrefix>person</mat-icon>
        <mat-label>E-Mail</mat-label>
        <input matInput [(ngModel)]="email" name="email" required />
      </mat-form-field>
      <mat-form-field hideRequiredMarker appearance="fill">
        <mat-icon matPrefix>https</mat-icon>
        <mat-label>Passwort</mat-label>
        <input
          matInput
          [(ngModel)]="password"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          required
        />
        <!-- <mat-icon
          class="far"
          [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          matSuffix
          (click)="showPassword = !showPassword"
        ></mat-icon> -->
      </mat-form-field>
      <button type="submit" style="display: none;">hidden submit</button>
    </form>
  </mat-card-content>
  <mat-card-actions class="login-button">
    <div style="width: 100%;">
      <button
        mat-flat-button
        (click)="login()"
        color="primary"
        style="width: 100%;"
        [disabled]="busy"
      >
        Login
      </button>
      <mat-progress-bar mode="indeterminate" *ngIf="busy"></mat-progress-bar>
    </div>
  </mat-card-actions>
  <a [routerLink]="['/register']" style="float: right;">Registrieren</a>
</mat-card>
