import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-dropdown',
  templateUrl: 'swodoc-node-dropdown.html',
  styleUrls: ['swodoc-node-dropdown.scss'],
})
export class SwodocNodeDropdownComponent {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  get value(): string {
    if (this.node.responses && this.node.responses.length > 0 && this.node.responses[0].value) {
      return this.node.responses[0].value;
    }

    return '';
  }
  set value(value: string) {
    this.node.responses = this.node.responses || [];
    if (this.node.responses.length === 0) {
      this.node.responses.push({ value });
    } else {
      this.node.responses[0] = { value };
    }
    this.changed.emit(this.node);
  }
}
