import { Injectable } from '@angular/core';
import { Paginated, Params } from '@feathersjs/feathers';
import { Product } from 'src/app/shared/models/product.model';
import { ServiceOptions } from 'src/app/shared/models/service.options.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService<Product> {
  constructor(feathers: FeathersService) {
    super('product', feathers.app);
  }
  async find(params?: Params, options?: ServiceOptions): Promise<Product[] | Paginated<Product>> {
    const _params = params || {};
    _params['service'] = 'swodoc';
    return super.find(_params, options);
  }
}
