import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

import { Group } from '../shared/models/group.model';
import { FeathersService } from '../shared/services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseService<Group> {
  constructor(feathersService: FeathersService) {
    super('group', feathersService.app);
  }
}
