import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PublicAttachmentBinaryService } from 'src/app/public/services/public-attachment-binary.service';
import { PublicAttachmentService } from 'src/app/public/services/public-attachment.service';
import { Attachment } from 'src/app/shared/models/attachment';
import { AuditNode } from 'src/app/shared/models/audit-node.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-picture',
  templateUrl: 'swodoc-node-picture.html',
  styleUrls: ['swodoc-node-picture.scss'],
})
export class SwodocNodePictureComponent implements OnInit {
  @Input()
  node: AuditNode;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();
  busy: boolean;
  image: string;

  constructor(
    private _attachmentBinary: PublicAttachmentBinaryService,
    // private _lightbox: Lightbox,
    private _attachmentService: PublicAttachmentService
  ) {}

  async ngOnInit() {
    this.busy = true;
    try {
      const query = {};
      query['metadata.templateNodeId'] = this.node.templateNodeId;
      const item = (await this._attachmentService.find({
        query,
      })) as Attachment[];
      if (item && item.length > 0) {
        this.image = (await this._attachmentBinary.get(
          item[item.length - 1]._id,
          item[item.length - 1].contentType
        )) as string;
        this.busy = false;
      }
    } catch (error) {
      console.log(error);
      this.busy = false;
    }
  }
  public async show() {
    // await this._lightbox.show([this.image], 0);
  }
}
