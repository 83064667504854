import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { ProfileComponent } from './profile.component';

const profileRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] }]
);

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    profileRouting,
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [ProfileComponent],
})
export class ProfileModule {}
