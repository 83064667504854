import { Injectable } from '@angular/core';

import { Inventory } from '../shared/models/inventory.model';
import { BaseService } from '../shared/services/base.service';
import { FeathersService } from '../shared/services/feathers.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService extends BaseService<Inventory> {
  constructor(feathers: FeathersService) {
    super('inventory', feathers.app);
  }
}
