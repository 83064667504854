<div class="page">
  <mat-form-field>
    <mat-label>Filter...</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput [(ngModel)]="filter" [disabled]="drawerOpen" />
  </mat-form-field>

  <app-spinner *ngIf="showSpinner"></app-spinner>

  <app-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" [filter]="filter" #table
    (itemSelect)="select($event)" [(disableSelection)]="drawerOpen">
    <ng-template appColumnHeader="" appRowColumn="schedule" let-row
      [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 60 }"> <mat-icon class="fa fa-calendar"
        *ngIf="row.scheduledReportId" style="font-size: 14px; height: 14px;width: 14px;"></mat-icon></ng-template>
    <ng-template appRowColumn="id" let-row>{{ row._id }}</ng-template>
    <ng-template appRowColumn="category" let-row>{{
      row.category
      }}</ng-template>
    <ng-template appRowColumn="title" let-row>
      <div [ngClass]="{ draft: row.draft }">
        <div *ngIf="row.draft" class="draft-label">Entwurf</div>
        {{ row.title }}
      </div>
    </ng-template>
    <ng-template appRowColumn="count" appColumnHeader="Anzahl" let-row>{{
      row.count
      }}</ng-template>

  </app-table>
</div>