import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TemplateService } from 'src/app/template/template.service';
import { environment } from 'src/environments/environment';

import { Template } from '../../shared/models/template.model';
import { Tenant } from '../../shared/models/tenant.model';
import { AuthService } from '../../shared/services/auth.service';
import { FullscreenService } from '../../shared/services/fullscreen.service';
import { TemplateReportComponent } from './template-report/template-report.component';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
})
export class TemplateDetailComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('tabs', { static: true })
  tabs: MatTabGroup;
  @ViewChild('report', { static: true })
  reportComponent: TemplateReportComponent;
  @Input()
  template: Template;
  @Input()
  sidenav: MatSidenav;
  @Output()
  updated = new EventEmitter();
  public fullscreen: boolean;
  private _fullscreenSubscription: Subscription;
  public tenant: Tenant;
  public templateUrl = 'about:blank';

  constructor(
    public fullscreenService: FullscreenService,
    public sanitizer: DomSanitizer,
    private _auth: AuthService,
    private _templateService: TemplateService
  ) {
    this._fullscreenSubscription = this.fullscreenService.fullscreen.subscribe(
      fullscreen => (this.fullscreen = fullscreen)
    );
  }

  ngOnInit() {
    this.tabs.selectedIndexChange.subscribe(index => {
      if (index === 1) {
        if ((this.template as any).report) {
          this.reloadTemplate();
        }
        setTimeout(() => {
          this.reportComponent.renderDesigner();
        }, 1);
      }
    });
  }

  async ngOnChanges() {
    const token = await this._auth.getToken();
    if (this.template) {
      this.templateUrl = `${environment.templateBuildUrl}/${this.template._id}/${token}`;
    }
  }

  ngOnDestroy() {
    this._fullscreenSubscription.unsubscribe();
  }

  toggleFullscreen() {
    this.fullscreenService.toggle();
  }

  close() {
    this.fullscreenService.set(false);
    this.sidenav.close();
    this.tabs.selectedIndex = 0;
    this.reportComponent.designer.report = undefined;
  }

  private async reloadTemplate() {
    const template = await this._templateService.get(this.template._id, undefined, { noCaching: true });
    (template as any).report = true;
    this.template = template;
  }
}
