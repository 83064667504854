import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EventService } from 'src/app/shared/services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isPublicPage: boolean;
  private _snackBar: MatSnackBarRef<SimpleSnackBar>;
  public hideNav: boolean;

  constructor(
    public auth: AuthService,
    eventService: EventService,
    snackBar: MatSnackBar,
    router: Router,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe('(max-width: 768px)').subscribe((result) => {
      this.hideNav = result.matches;
    });
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isPublicPage = event.url.startsWith('/p/');
      });

    eventService.events.subscribe((event) => {
      try {
        if (this._snackBar) {
          this._snackBar.dismiss();
          this._snackBar = undefined;
        }
        this._snackBar = snackBar.open(event.message, undefined, {
          duration: 3000,
        });
      } catch (e) {}
    });
  }
}
