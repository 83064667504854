import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public email = '';
  public password: string;
  public busy: boolean;
  public showPassword: boolean;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    public snackBar: MatSnackBar,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {}

  public async login() {
    this.snackBar.dismiss();
    try {
      this.busy = true;
      await this._auth.login(this.email, this.password);
      this.busy = false;
      this._router.navigateByUrl(
        this._route.snapshot.queryParams['returnUrl'] || '/'
      );
    } catch (e) {
      this.busy = false;
      this.snackBar.open(e.message, undefined, {
        duration: 3000,
      });
    }
  }
}
