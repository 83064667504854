import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { buildNumber } from 'src/version';

Sentry.init({
  dsn: 'https://115221aa2a0e4d1b838c85bde2de2388@o314539.ingest.sentry.io/1793232',

  release: `swodoc-web@${
    // @ts-ignore
    buildNumber !== '#{Build.BuildNumber}#' ? buildNumber : 'dev'
  }`,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.production) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
        return;
      }

      Sentry.captureException(error.originalError || error);
    }
  }
}
