<form [formGroup]="createTemplateForm">
  <mat-form-field hideRequiredMarker="true">
    <input matInput placeholder="Name" formControlName="title" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Beschreibung (optional)" formControlName="description">
  </mat-form-field>

  <app-template-category formControlName="category"></app-template-category>

  <mat-form-field>
    <mat-select formControlName="draft">
      <mat-option [value]="true">Entwurf</mat-option>
      <mat-option [value]="false">Live</mat-option>
    </mat-select>
  </mat-form-field>

</form>
