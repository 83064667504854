import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Group } from '../../shared/models/group.model';
import { Region } from '../../shared/models/region.model';
import { Rightset } from '../../shared/models/rightset.model';
import { Tenant } from '../../shared/models/tenant.model';
import { GroupService } from '../group.service';

// import { RegionService } from '../../region/region.service';
// import { RightsetService } from '../../rightset/rightset.service';
@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss'],
})
export class GroupDetailComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('regionAutocomplete', { static: true })
  regionAutocomplete: MatAutocomplete;
  @ViewChild('rightsetAutocomplete', { static: true })
  rightsetAutocomplete: MatAutocomplete;

  @Input()
  group: Group;
  @Input()
  tenant: Tenant;
  @Input()
  sidenav: MatSidenav;
  @Output()
  updated = new EventEmitter();

  public showSpinner: boolean;
  public allRegions$: Observable<Region[]>;
  private _allRegions: Region[];
  private _allRightsets: Rightset[];
  public allRightsets$: Observable<Rightset[]>;
  public rightsets: Rightset[];
  public regionCtrl = new FormControl();
  private _regionAutocompleteSubscription: Subscription;
  private _rightsetAutocompleteSubscription: Subscription;
  public rightsetCtrl = new FormControl();

  constructor(private _groupService: GroupService) {}

  ngOnInit() {
    this.loadRegions();
    this.loadRightSets();

    this._regionAutocompleteSubscription = this.regionAutocomplete.optionSelected.subscribe(
      (selected: MatAutocompleteSelectedEvent) => {
        this.group.regionId = selected.option.value._id;
      }
    );
    this._rightsetAutocompleteSubscription = this.rightsetAutocomplete.optionSelected.subscribe(
      (seleted: MatAutocompleteSelectedEvent) => {
        this.rightsets.push(seleted.option.value);
        this.group.rightSet.push(seleted.option.value._id);
        this.rightsetCtrl.reset();
      }
    );
  }

  ngOnChanges() {
    if (this._allRegions && this._allRightsets) {
      this.regionCtrl.setValue(this._allRegions.find(f => f._id === this.group.regionId));
      this.rightsets = this._allRightsets.filter(f => this.group.rightSet.includes(f._id));
    }
  }

  ngOnDestroy() {
    this._regionAutocompleteSubscription.unsubscribe();
    this._rightsetAutocompleteSubscription.unsubscribe();
  }
  async loadRegions() {
    // this._allRegions =
    //   this._regionService.cache.length === 0
    //     ? ((await this._regionService.find(this.tenant.tenantId)) as Region[])
    //     : this._regionService.cache;
    const filter = (value: string) => {
      if (value && !(value as any).label) {
        const filterValue = value.toLowerCase();
        return this._allRegions.filter(f => f.label.toLowerCase().includes(filterValue));
      }
      return this._allRegions;
    };
    this.allRegions$ = this.regionCtrl.valueChanges.pipe(
      startWith(''),
      map(value => filter(value))
    );
  }
  displayRegionFct(region: Region) {
    return region ? region.label : undefined;
  }

  async loadRightSets() {
    // this._allRightsets =
    //   this._rightsetService.cache.length === 0
    //     ? ((await this._rightsetService.find()) as Rightset[])
    //     : this._rightsetService.cache;
    const filter = (value: string) => {
      if (value && !(value as any).description) {
        const filterValue = value.toLowerCase();
        return this._allRightsets.filter(f => f.description.toLowerCase().includes(filterValue));
      }
      return this._allRightsets;
    };
    this.allRightsets$ = this.rightsetCtrl.valueChanges.pipe(
      startWith(''),
      map(value => filter(value))
    );
  }

  displayRightsetFct(rightset: Rightset) {
    return rightset ? rightset.description : undefined;
  }

  public removeRightset(rightset: Rightset) {
    this.rightsets = this.rightsets.filter(f => f !== rightset);
    this.group.rightSet.splice((this.group.rightSet as any[]).indexOf(rightset._id), 1);
  }

  public moveRightsetUp(index: number, rightset: Rightset): void {
    this.rightsets.splice(index, 1);
    this.rightsets.splice(index - 1, 0, rightset);
    this.group.rightSet.splice(index, 1);
    (this.group.rightSet as any[]).splice(index - 1, 0, rightset._id);
  }

  public moveRightsetDown(index: number, rightset: Rightset): void {
    this.rightsets.splice(index, 1);
    this.rightsets.splice(index + 1, 0, rightset);
    this.group.rightSet.splice(index, 1);
    (this.group.rightSet as any[]).splice(index + 1, 0, rightset._id);
  }

  async save() {
    // let updatedGroup: Group;
    if (this.group._id) {
      const { _id, selected, timestamp, ...rest } = this.group as any;
      // updatedGroup = (await this._groupService.patch(this.tenant.tenantId, _id, rest)) as Group;
    } else {
      // updatedGroup = (await this._groupService.create(this.tenant.tenantId, this.group)) as Group;
    }
    this.sidenav.close();
    // this.updated.emit(updatedGroup);
  }
}
