import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-menu-header',
  templateUrl: './mobile-menu-header.component.html',
  styleUrls: ['./mobile-menu-header.component.scss'],
})
export class MobileMenuHeaderComponent {
  @Output()
  public menuClicked = new EventEmitter();
}
