import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { DetailDrawerComponent } from '../../shared/layout/detail-drawer/detail-drawer.component';
import { TableComponent } from '../../shared/layout/table/table.component';
import { Group } from '../../shared/models/group.model';
import { Tenant } from '../../shared/models/tenant.model';
import { GroupService } from '../group.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: true })
  drawer: DetailDrawerComponent;
  @ViewChild('table', { static: true })
  table: TableComponent;

  private _dataSourceSubject = new Subject<Group[]>();
  public dataSource = this._dataSourceSubject.asObservable();

  public displayedColumns = ['label', 'region', 'rightsets'];
  public selectedGroup: Group = new Group();
  public tenant: Tenant;
  public showSpinner: boolean;
  public drawerOpen: boolean;
  private _routeSubscription: Subscription;
  private _drawerClosedSubscription: Subscription;
  public filter: string;

  constructor(private _route: ActivatedRoute, private _groupService: GroupService) {}

  ngOnInit() {
    this._routeSubscription = this._route.parent.data.subscribe((data: { tenant: Tenant }) => {
      this.tenant = data.tenant;
      this.load();
    });
    this._drawerClosedSubscription = this.drawer.closedStart.subscribe(() => {
      this.table.unselectAll();
    });
  }

  ngOnDestroy() {
    this._routeSubscription.unsubscribe();
    this._drawerClosedSubscription.unsubscribe();
  }

  async load() {
    this.showSpinner = true;
    const groups = (await this._groupService.find()) as Group[];
    this._dataSourceSubject.next(groups);
    this.showSpinner = false;
  }

  select(group: Group) {
    this.selectedGroup = { ...group };
    if (this.selectedGroup.rightSet) {
      this.selectedGroup.rightSet = [...this.selectedGroup.rightSet];
    }
    this.drawer.open();
  }

  add() {
    this.selectedGroup = new Group();
    this.drawer.open();
  }

  updated(item) {
    this._dataSourceSubject.next(this._groupService.cache);
  }
}
