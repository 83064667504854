import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-text',
  templateUrl: 'swodoc-node-text.html',
  styleUrls: ['swodoc-node-text.scss'],
})
export class SwodocNodeTextComponent implements OnInit {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();
  public autocomplete: string;

  get text(): string {
    if (
      this.node.responses &&
      this.node.responses.length > 0 &&
      this.node.responses[0].value
    ) {
      return this.node.responses[0].value;
    }

    return '';
  }
  set text(value: string) {
    this.node.responses = this.node.responses || [];
    if (this.node.responses.length === 0) {
      this.node.responses.push({ value });
    } else {
      this.node.responses[0] = { value };
    }
    this.changed.emit(this.node);
  }

  ngOnInit() {
    if (this.node && this.node.title) {
      switch (this.node.title.toLowerCase()) {
        case 'vorname':
          this.autocomplete = 'given-name';
          break;
        case 'nachname':
        case 'name':
          this.autocomplete = 'family-name';
          break;
        case 'email':
          this.autocomplete = 'email';
          break;
        case 'firma':
          this.autocomplete = 'organization';
          break;
        case 'job':
          this.autocomplete = 'organization-title';
          break;
        case 'straße':
        case 'strasse':
        case 'adresse':
          this.autocomplete = 'street-address';
          break;
        case 'postleitzahl':
        case 'plz':
          this.autocomplete = 'postal-code';
          break;
        case 'telefon':
        case 'fon':
        case 'tel':
          this.autocomplete = 'tel';
          break;
        default:
          this.autocomplete = 'off';
      }
    }
  }
}
