import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-multiplechoice',
  templateUrl: 'swodoc-node-multiplechoice.html',
  styleUrls: ['swodoc-node-multiplechoice.scss'],
})
export class SwodocNodeMultiplechoiceComponent implements OnInit {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();
  allowMultiple: boolean;
  selectedText = '';
  private _value: string | string[];

  ngOnInit() {
    this.allowMultiple =
      !this.node.options || this.node.options.allowMultiple === undefined || this.node.options.allowMultiple
        ? true
        : false;
    if (this.node && this.node.selectables && this.node.selectables.length > 0) {
      if (this.allowMultiple) {
        this._value = [];
        this.node.selectables
          .filter(f => f.selected)
          .forEach(m => {
            (this._value as string[]).push(m.value);
            this.selectedText += `${m.label}\n`;
          });
      } else {
        const v = this.node.selectables.find(f => f.selected);
        this._value = v ? v.value : '';
      }
    } else {
      this._value = this.allowMultiple ? [] : '';
    }
  }

  get value(): string | string[] {
    return this._value;
  }

  set value(value: string | string[]) {
    this._value = value;
    this.node.selectables.forEach(m => (m.selected = false));
    if (this.allowMultiple && Array.isArray(value)) {
      this.selectedText = '';
      value.forEach(item => {
        const sel = this.node.selectables.find(f => f.value === item);
        if (sel) {
          sel.selected = true;
          this.selectedText += `${sel.label}\n`;
        }
      });
    } else {
      const sel = this.node.selectables.find(f => f.value === value);
      if (sel) {
        sel.selected = true;
      }
    }
    this.changed.emit(this.node);
  }
}
