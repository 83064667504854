import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/buy-credits/product.service';
import { CurrentUser } from 'src/app/shared/models/current-user.model';
import { Product } from 'src/app/shared/models/product.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-buy-credits',
  templateUrl: './buy-credits.component.html',
  styleUrls: ['./buy-credits.component.scss'],
})
export class BuyCreditsComponent implements OnInit {
  products: Product[];
  fade: boolean;

  private _selected: string;
  get selected() {
    return this._selected;
  }
  set selected(value: string) {
    this.fade = true;
    this._selected = value;
  }
  private _currentUser: CurrentUser;

  constructor(
    private _route: ActivatedRoute,
    private _productService: ProductService,
    @Inject(DOCUMENT) private _document: Document,
    private _auth: AuthService
  ) {}

  async ngOnInit() {
    this._currentUser = this._auth.currentUser;
    if (
      this._route.snapshot.queryParams &&
      this._route.snapshot.queryParams['selected']
    ) {
      this.selected = this._route.snapshot.queryParams['selected'];
    }
    const products = (await this._productService.find()) as Product[];
    if (products && products.length > 0) {
      products.shift();
      this.products = products;
    }
  }

  public buy() {
    this._document.location.href = `https://app.monsum.com/checkout/0/82ef54788247e241b5ce9b9e5b01b73b/${
      this._currentUser.tenant.monsum.HASH
    }/${this.selected}?mapping=false&lang=de&redirectUrl=${btoa(
      window.location.origin
    )}`;
  }
}
