import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

export interface AddedImage {
  auditNodeId: string;
  data: string;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-signature',
  templateUrl: 'swodoc-node-signature.html',
  styleUrls: ['swodoc-node-signature.scss'],
})
export class SwodocNodeSignatureComponent implements AfterViewInit {
  @Input()
  node: any;
  @Output()
  changed = new EventEmitter<any>();
  @Output()
  busy = new EventEmitter<boolean>();
  @Output()
  imageAdded = new EventEmitter<AddedImage>();

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @ViewChild('wrapper') wrapper: ElementRef;

  public signaturePadOptions = {
    minWidth: 1,
    backgroundColor: 'rgb(255, 255, 255)',
    penColor: 'black',
  };

  constructor() {}

  ngAfterViewInit(): void {
    this.signaturePad.set(
      'canvasWidth',
      this.wrapper.nativeElement.offsetWidth - 60
    );
    this.signaturePad.clear();
  }

  public drawComplete(): void {
    this.imageAdded.emit({
      auditNodeId: this.node.id,
      data: this.signaturePad.toDataURL('image/jpeg', 1),
    });
  }

  public clear(): void {
    this.signaturePad.clear();
  }
}
