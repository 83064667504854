import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

import { Report } from '../models/report.model';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService<Report> {
  constructor(feathers: FeathersService) {
    super('report', feathers.app);
  }
}
