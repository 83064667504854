import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input() avatar: string;
  avatarImage: SafeStyle;

  constructor(private _domsanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.avatar) {
      this.avatarImage = this._domsanitizer.bypassSecurityTrustStyle(`url("data:image/svg+xml;base64,${this.avatar}")`);
    }
  }
}
