import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
  selector: 'app-xlsx-to-table',
  templateUrl: './xlsx-to-table.component.html',
  styleUrls: ['./xlsx-to-table.component.scss']
})
export class XlsxToTableComponent implements OnInit {
  @Output() tableData = new EventEmitter();

  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver = false;
  public importedData: AOA;
  public displayedColumns: string[] = [];
  public columnsToDisplay: string[] = [];

  private _dataSourceSubject = new Subject<any[]>();
  public dataSource = this._dataSourceSubject.asObservable();

  public showSpinner: boolean;
  public busy: boolean;
  public importFailed: boolean;
  public importCount: number;

  @Input() validationFunction: (data: any[]) => any[] = (data: any) => data;

  constructor() {}

  ngOnInit() {}

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public async dropped(fileList: FileList) {
    this.importedData = await this.readFile(fileList.item(0));
    this.setDisplayedColumns();
    this.setDataSource();
  }

  private async readFile(file: File): Promise<AOA> {
    return new Promise<AOA>((resolve, reject) => {
      this.showSpinner = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          resolve(<AOA>XLSX.utils.sheet_to_json(ws, { header: 1 }));
        } catch (e) {
          reject(e);
        }
        this.showSpinner = false;
      };
      reader.readAsBinaryString(file);
    });
  }

  private setDisplayedColumns() {
    if (this.importedData) {
      this.importedData[0].forEach(colName =>
        this.displayedColumns.push(colName)
      );
      this.columnsToDisplay = this.displayedColumns.slice();
    }
  }

  private setDataSource() {
    const items = this.importedData.splice(1).map(row => {
      const item = {};
      for (const col of this.displayedColumns) {
        item[col] = row[this.displayedColumns.indexOf(col)];
      }
      return item;
    });
    this.validationFunction(items);
    this._dataSourceSubject.next(items);
    const itemsToImport = items.filter((f: any) => !f.ignore);
    this.tableData.emit(itemsToImport);
  }
}
