import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { SwodocNodeInventoryModalModule } from './modal/swodoc-node-inventory.modal.module';
import { SwodocNodeInventoryComponent } from './swodoc-node-inventory';

@NgModule({
  declarations: [SwodocNodeInventoryComponent],
  imports: [
    CommonModule,
    SwodocNodeInventoryModalModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [SwodocNodeInventoryComponent]
})
export class SwodocNodeInventoryModule {}
