import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../user.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent implements OnInit {
  public newPassword: string;
  public busy: boolean;
  public showPassword: boolean;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public userService: UserService
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  async resetPassword() {
    this.busy = true;
    let successful = false;
    try {
      await this.userService.patch(this.data.userId, {
        password: this.newPassword,
      } as any);
      successful = true;
    } catch (e) {
      successful = false;
    }
    this.busy = false;
    this.dialogRef.close(successful);
  }
}
