import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inventory } from 'src/app/shared/models/inventory.model';

import { PublicInventoryService } from './public-inventory.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'swodoc-node-inventory-modal',
  templateUrl: 'swodoc-node-inventory.modal.html',
  styleUrls: ['swodoc-node-inventory.modal.scss'],
  providers: [PublicInventoryService],
})
export class SwodocNodeInventoryModalComponent implements OnInit {
  public inventory: Inventory;
  public data: any[];
  public columns: string[];
  private selectValue: string;
  private inventoryId: string;
  public busy: boolean;

  constructor(
    private inventoryService: PublicInventoryService,
    public dialogRef: MatDialogRef<SwodocNodeInventoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData: any
  ) {
    this.inventoryId = dialogData.inventoryId;
    this.columns = dialogData.columns;
    this.selectValue = dialogData.selectValue;
  }

  async ngOnInit() {
    this.busy = true;
    const result = await this.inventoryService.get(this.inventoryId);
    if (result) {
      this.inventory = result;
      this.data = this.inventory.data;
    }
    const x = [].includes;
    this.busy = false;
  }

  public search(event) {
    const searchString = String(event.target.value);
    this.data = this.inventory.data.filter((o) =>
      searchString
        .toLowerCase()
        .split(' ')
        .every((keyword) =>
          Object.values(o).some((value) =>
            String(value).toLowerCase().includes(keyword)
          )
        )
    );
  }

  public onSearchCancel(x) {
    this.data = this.inventory.data;
  }

  public select(item: any) {
    this.selectValue
      ? this.dismiss({ value: item[this.selectValue], selectedItem: item })
      : this.dismiss({ value: item, selectedItem: item });
  }

  public dismiss(data?) {
    this.dialogRef.close(data);
  }

  public upperFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
