<mat-form-field
  appearance="standard"
  [hintLabel]="node.description"
  *ngIf="!node?.options || node?.options?.date"
>
  <mat-label>{{ node.title }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [(ngModel)]="displayValue"
    [name]="node.title"
    [required]="node.mandatory"
    [max]="maxDate"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [touchUi]="!isDesktop"></mat-datepicker>
</mat-form-field>
<div
  style="width: 50px;"
  *ngIf="!node?.options || (node?.options?.date && node?.options?.time)"
></div>
<mat-form-field
  appearance="standard"
  [hintLabel]="node.description"
  *ngIf="!node?.options || node?.options?.time"
>
  <mat-label>{{ node.title }}</mat-label>
  <input
    matInput
    [ngxTimepicker]="picker"
    [(ngModel)]="displayValueTime"
    [name]="node.title"
    [required]="node.mandatory"
    [format]="24"
  />
  <ngx-material-timepicker
    #picker
    [theme]="swodocTheme"
    [defaultTime]="defaultTime"
  ></ngx-material-timepicker>
  <mat-icon matSuffix>schedule</mat-icon>
</mat-form-field>
