import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrentUser } from 'src/app/shared/models/current-user.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TenantService } from 'src/app/tenant/tenant.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit {
  public currentUser: CurrentUser;
  public tenantForm: FormGroup;
  public users: User[];
  public isAdmin: boolean;

  constructor(
    private _auth: AuthService,
    private _fb: FormBuilder,
    private _tenantService: TenantService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this.currentUser = this._auth.currentUser;
    this.isAdmin = this.currentUser.roles.includes('admin');
    this.tenantForm = this._fb.group({
      organisation: [this.currentUser.tenant.name, [Validators.required]],
    });
    this.loadUsers();
  }

  async update() {
    await this._tenantService.patch(this.currentUser.tenant._id, {
      name: this.tenantForm.controls['organisation'].value,
    });
  }

  async loadUsers() {
    // this.showSpinner = true;
    const users = (await this._userService.find()) as User[];
    if (users.length > 10) {
      users.splice(10, users.length - 10);
    }
    this.users = users;
    // this.showSpinner = false;
  }
}
