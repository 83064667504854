import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { CreditsTileComponent } from 'src/app/shared/layout/credits-tile/credits-tile.component';
import { MobileMenuHeaderComponent } from 'src/app/shared/layout/mobile-menu-header/mobile-menu-header.component';
import { UserAvatarComponent } from 'src/app/shared/layout/user-avatar/user-avatar.component';
import { WelcomeTileComponent } from 'src/app/shared/layout/welcome-tile/welcome-tile.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { DeleteDialogComponent } from './layout/delete-dialog/delete-dialog.component';
import { DetailDrawerComponent } from './layout/detail-drawer/detail-drawer.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ItemSelectorComponent } from './layout/item-selector/item-selector.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { ProgressSpinnerDialogComponent } from './layout/progress-spinner-dialog/progress-spinner-dialog.component';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { TableRowColumnDirective } from './layout/table/table-row-column.directive';
import { TableComponent } from './layout/table/table.component';
import { TenantAutocompleteComponent } from './layout/tenant-autocomplete/tenant-autocomplete.component';
import { XlsxToTableComponent } from './layout/xlsx-to-table/xlsx-to-table.component';
import { UpperFirstPipe } from './pipes/upper-first.pipe';
import { UsernamePipe } from './pipes/username.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatInputModule,
    FileUploadModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    SpinnerComponent,
    DetailDrawerComponent,
    TableComponent,
    TableRowColumnDirective,
    UpperFirstPipe,
    XlsxToTableComponent,
    TenantAutocompleteComponent,
    ItemSelectorComponent,
    DeleteDialogComponent,
    UserAvatarComponent,
    CopyClipboardDirective,
    ProgressSpinnerDialogComponent,
    CreditsTileComponent,
    UsernamePipe,
    WelcomeTileComponent,
    MobileMenuHeaderComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    SpinnerComponent,
    DetailDrawerComponent,
    TableComponent,
    TableRowColumnDirective,
    UpperFirstPipe,
    XlsxToTableComponent,
    TenantAutocompleteComponent,
    ItemSelectorComponent,
    UserAvatarComponent,
    CopyClipboardDirective,
    CreditsTileComponent,
    UsernamePipe,
    WelcomeTileComponent,
    MobileMenuHeaderComponent,
  ],
  entryComponents: [DeleteDialogComponent, ProgressSpinnerDialogComponent],
})
export class SharedModule {}
