<div class="page">
  <mat-form-field class="full-width">
    <input matInput [(ngModel)]="filter" placeholder="Filter..." />
  </mat-form-field>

  <app-table
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [filter]="filter"
    [disableSelection]="true"
  >
    <ng-template appRowColumn="date" appColumnHeader="Datum" let-row>{{
      row.date
    }}</ng-template>
    <ng-template
      appRowColumn="invoiceNo"
      appColumnHeader="RechnungsNr."
      let-row
      [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 90 }"
      >{{ row.invoiceNo }}</ng-template
    >
    <ng-template appRowColumn="title" let-row>{{ row.title }}</ng-template>
    <ng-template
      appRowColumn="download"
      let-row
      [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 60 }"
    >
      <a mat-icon-button [href]="row.url"
        ><mat-icon>cloud_download</mat-icon></a
      >
    </ng-template>
  </app-table>
</div>
