import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[appRowColumn]',
})
export class TableRowColumnDirective {
  @Input() appRowColumn: string;
  @Input() appColumnStyle: object;
  @Input() appColumnHeader: string;

  constructor(public templateRef: TemplateRef<any>) {}
}
