import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { ShareTemplateDialogComponent } from 'src/app/template/share-template-dialog/share-template-dialog.component';

import { DeleteDialogService } from '../../shared/layout/delete-dialog/delete-dialog.service';
import { Template } from '../../shared/models/template.model';
import { CopyTemplateDialogComponent } from '../copy-template-dialog/copy-template-dialog.component';
import { CreateEditTemplateDialogComponent } from '../create-edit-template-dialog/create-edit-template-dialog.component';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-template-more-menu',
  templateUrl: './template-more-menu.component.html',
  styleUrls: ['./template-more-menu.component.scss'],
})
export class TemplateMoreMenuComponent implements OnInit {
  @Input()
  template: Template;
  @Output()
  update = new EventEmitter<Template>();
  @Output()
  deleted = new EventEmitter<string>();

  constructor(
    private _dialog: MatDialog,
    private _templatService: TemplateService,
    private _deleteDialog: DeleteDialogService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  openMenu(event: Event) {
    event.stopPropagation();
  }

  duplicate() {
    this._dialog
      .open(CopyTemplateDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { template: { ...this.template } },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.update.emit(result);
        }
      });
  }

  edit() {
    this._dialog
      .open(CreateEditTemplateDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { template: { ...this.template } },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.update.emit(result);
        }
      });
  }

  async toggleDraft() {
    const draft = !this.template.draft;
    const template = await this._templatService.patch(this.template._id, { draft });
    this.template.draft = draft;
    // this.update.emit(template);
  }

  async share() {
    this._dialog
      .open(ShareTemplateDialogComponent, {
        width: '450px',
        maxHeight: '800px',
        autoFocus: false,
        data: { template: { ...this.template } },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.update.emit(result);
        }
      });
  }

  async delete() {
    const displayErrorToast = (message: string) => {
      this._snackBar.open(message, undefined, {
        duration: 3000,
      });
    };
    return new Promise(resolve => {
      this._deleteDialog.show('die Vorlage', this.template.title);
      this._deleteDialog.afterDelConfirm.pipe(take(1)).subscribe(async () => {
        const deletedTemplate = await this._templatService.remove(this.template._id).catch(displayErrorToast);
        if (deletedTemplate) {
          this.deleted.emit(this.template._id);
        }
      });
    });
  }
}
