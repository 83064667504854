<div class="wrapper">
  <div class="title--wrapper">
    <p class="title">{{ node.title }}</p>
    <p *ngIf="node.description">
      <small text-wrap class="small">{{ node.description }}</small>
    </p>
  </div>
  <button mat-icon-button (click)="openModal()">
    <mat-icon>input</mat-icon>
  </button>
</div>
<div>{{value}}</div>
<hr />