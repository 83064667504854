import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { buildNumber } from 'src/version';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public currentNav: string;
  public currentUserAvatar: SafeHtml;
  public isAuth: boolean;
  public isAdmin: boolean;
  public readonly buildNumber = buildNumber;

  constructor(private _router: Router, public auth: AuthService) {
    this.auth.isAuthenticated.subscribe((isAuth) => {
      this.isAuth = isAuth;
      if (this.isAuth) {
        this.isAdmin = this.auth.currentUser.roles.includes('admin');
      }
    });
    this._router.events
      .pipe(filter((f) => f instanceof ActivationEnd))
      .subscribe((a: ActivationEnd) => {
        if (a.snapshot.url.length === 0) {
          this.currentNav = 'home';
          return;
        }
        const path = a.snapshot.url[0].path;
        switch (path) {
          case 'user':
          case 'group':
          case 'region':
          case 'rightset':
          case 'apikey':
          case 'buy':
            this.currentNav = 'security';
            break;
          case 'template':
            this.currentNav = 'documents';
            break;
          default:
            this.currentNav = undefined;
        }
      });
  }

  ngOnInit() {}

  async logout() {
    await this.auth.logout();
  }
}
