import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-welcome-tile',
  templateUrl: './welcome-tile.component.html',
  styleUrls: ['./welcome-tile.component.scss'],
})
export class WelcomeTileComponent implements OnInit {
  public firstName: string;

  constructor(private _auth: AuthService) {
    this.firstName = this._auth.currentUser.firstName;
  }

  ngOnInit() {}
}
