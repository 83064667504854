import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-users-tile',
  templateUrl: './users-tile.component.html',
  styleUrls: ['./users-tile.component.scss'],
})
export class UsersTileComponent implements OnInit {
  @Input() users: User[];

  constructor() {}

  ngOnInit() {}
}
