import { MongoModel } from './_mongo.model';

export class User extends MongoModel {
  firstName: string;
  lastName: string;
  email: string;
  tenantId: string;
  avatar: string;
  isVerified: boolean;
  roles: string[];
}
