<div class="page">
  <div class="col-3">
    <div class="row-top">
      <app-tenant-tile
        class="tile tenant-tile"
        [tenantForm]="tenantForm"
        (saveTenant)="update()"
      ></app-tenant-tile>
      <app-credits-tile
        class="tile credits-tile"
        [credits]="currentUser.tenant.credits"
        [isAdmin]="isAdmin"
      ></app-credits-tile>
      <app-security-tile class="tile security-tile"></app-security-tile>
    </div>
    <div class="tile invoice-tile">
      <mat-card>
        <app-invoice-table [maxDisplay]="3"></app-invoice-table>
        <a
          mat-stroked-button
          color="primary"
          class="full-width"
          [routerLink]="['invoices']"
        >
          Alle anzeigen
        </a>
      </mat-card>
    </div>
  </div>
  <app-users-tile class="tile user-tile" [users]="users"></app-users-tile>
</div>
