<app-detail-drawer #drawer>
  <div body>
    <div class="button-wrapper">
      <button mat-button (click)="add()" color="primary" [disabled]="drawerOpen">
        <mat-icon>add</mat-icon> Benutzer hinzufügen
      </button>
      <button mat-button (click)="import()" color="primary" [disabled]="drawerOpen">
        <mat-icon>save_alt</mat-icon> Benutzer importieren
      </button>
    </div>

    <mat-form-field>
      <input matInput [(ngModel)]="filter" placeholder="Filter..." [disabled]="drawerOpen" />
    </mat-form-field>

    <app-spinner *ngIf="showSpinner"></app-spinner>

    <app-table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [filter]="filter"
      #table
      (itemSelect)="select($event)"
      [(disableSelection)]="drawerOpen"
    >
      <ng-template appRowColumn="id" let-row>{{ row._id }}</ng-template>
      <ng-template
        appRowColumn="avatar"
        let-row
        appColumnHeader=" "
        [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 80 }"
        ><app-user-avatar [avatar]="row.avatar"></app-user-avatar
      ></ng-template>
      <ng-template appRowColumn="firstName" appColumnHeader="Vorname" let-row>{{ row.firstName }}</ng-template>
      <ng-template appRowColumn="lastName" let-row>{{ row.lastName }}</ng-template>
      <ng-template appRowColumn="email" let-row>{{ row.email }}</ng-template>
      <ng-template appRowColumn="more" let-row [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 60 }">
        <app-user-more-menu [user]="row"></app-user-more-menu>
      </ng-template>
    </app-table>
  </div>
  <div detail>
    <app-user-detail [user]="selectedUser" [sidenav]="drawer" (updated)="updated($event)"></app-user-detail>
  </div>
</app-detail-drawer>
