import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentUser } from 'src/app/shared/models/current-user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: CurrentUser;
  profileForm: FormGroup;
  passwordForm: FormGroup;
  showResetPassword: boolean;
  busy: boolean;
  isAdmin: boolean;

  constructor(
    private _auth: AuthService,
    private _fb: FormBuilder,
    private _userService: UserService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.user = this._auth.currentUser;
    this.isAdmin = this.user.roles.includes('admin');
    this.profileForm = this._fb.group({
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      email: [{ value: this.user.email, disabled: true }, [Validators.required, Validators.email]],
    });
    this.passwordForm = this._fb.group(
      {
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: this.checkPasswords }
    );
  }

  async update() {
    try {
      this.busy = true;
      await this._userService.patch(this.user._id, {
        firstName: this.profileForm.controls['firstName'].value,
        lastName: this.profileForm.controls['lastName'].value,
      });
      this._auth.currentUser.firstName = this.profileForm.controls['firstName'].value;
      this._auth.currentUser.lastName = this.profileForm.controls['lastName'].value;

      this._snackBar.open('Gespeichert', undefined, {
        duration: 3000,
      });
    } catch (e) {
      this._snackBar.open(e.message, undefined, {
        duration: 3000,
      });
    } finally {
      this.busy = false;
    }
  }
  async updatePassword() {
    try {
      this.busy = true;
      await this._userService.patch(this.user._id, {
        password: this.passwordForm.controls['password'].value,
      } as any);

      this._snackBar.open('Passwort erfolgreich geändert', undefined, {
        duration: 3000,
      });
    } catch (e) {
      this._snackBar.open(e.message, undefined, {
        duration: 3000,
      });
    } finally {
      this.busy = false;
      this.showResetPassword = false;
      this.passwordForm.controls['password'].setValue(undefined);
      this.passwordForm.controls['confirmPassword'].setValue(undefined);
    }
  }
  private checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
