<mat-table [dataSource]="matDataSource" class="mat-elevation-z5" *ngIf="matDataSource">
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <mat-header-cell *matHeaderCellDef [ngStyle]="rowColumnTemplates.get(column)?.appColumnStyle">
      {{rowColumnTemplates.get(column)?.appColumnHeader || column | upperFirst}}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="{'active':row.selected && isFirstColumn(column)}" [ngStyle]="rowColumnTemplates.get(column)?.appColumnStyle">
      <ng-container [ngTemplateOutlet]="rowColumnTemplates.get(column).templateRef" [ngTemplateOutletContext]="{$implicit: row}"></ng-container>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [ngClass]="{ 'ignore':row.ignore, 'failed': row.failed}"></mat-row>
</mat-table>
