import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupService } from './group.service';

// import { RegionPipe } from './group-list/region.pipe';
// import { RightsetsPipe } from './group-list/rightsets.pipe';
const groupRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: 'group', component: GroupListComponent, canActivate: [AuthGuard] },
]);

@NgModule({
  imports: [
    groupRouting,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UserModule,
    MatTableModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDividerModule,
  ],
  declarations: [GroupListComponent, GroupDetailComponent],
  providers: [GroupService],
})
export class GroupModule {}
