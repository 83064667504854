<mat-toolbar
  class="header mat-elevation-z4"
  style="z-index: 10"
  [ngClass]="{ fullscreen: fullscreenService.fullscreen | async }"
>
  <!-- <div class="breadcrumbs">
    <div *ngFor="let bc of breadcrumbs; let last = last">
      <a [href]="bc.path">{{bc.title}}</a>
      <span *ngIf="!last">/</span>
    </div>
  </div> -->
  <img src="assets/logo.svg" width="100" />
  <span class="spacer"></span>
  <span></span>
</mat-toolbar>
