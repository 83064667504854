import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { v4 } from 'uuid';

import { Template } from '../../shared/models/template.model';
import { TemplateViewModel } from '../../shared/models/template.view.model';
import { Tenant } from '../../shared/models/tenant.model';
import { CopyService } from '../../shared/services/copy.service';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-copy-template-dialog',
  templateUrl: './copy-template-dialog.component.html',
  styleUrls: ['./copy-template-dialog.component.scss'],
})
export class CopyTemplateDialogComponent implements OnInit {
  template: TemplateViewModel;
  duplicateReport: boolean;
  duplicateMatch: boolean;
  error: string;
  busy: boolean;
  formIsValid: boolean;

  constructor(
    public dialogRef: MatDialogRef<CopyTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tenant: Tenant; template: Template },
    private _templateService: TemplateService,
    private _copyService: CopyService
  ) {
    if (!this.data || !this.data.template) {
      throw new Error('Template is missing');
    }

    this.template = Object.assign(new TemplateViewModel(), this.data.template);
  }

  ngOnInit() {
    this.duplicateReport = (this.template as any).report !== undefined;
    this.duplicateMatch = (this.template as any).match !== undefined;
    this.template.title += ' (Copy)';
  }

  async copy() {
    this.busy = true;
    try {
      const origId = this.template._id;
      this.template._id = v4();
      const result = await this._templateService.create(this.template);
      if (result) {
        if (this.duplicateReport) {
          await this._copyService.copy({
            id: origId,
            targetId: this.template._id,
            sourceCollection: 'report',
            targetCollection: 'report',
            sourceDb: this.data.tenant.tenantId,
            targetDb: this.data.tenant.tenantId,
          });
        }
        if (this.duplicateMatch) {
          await this._copyService.copy({
            id: origId,
            targetId: this.template._id,
            sourceCollection: 'match',
            targetCollection: 'match',
            sourceDb: this.data.tenant.tenantId,
            targetDb: this.data.tenant.tenantId,
          });
        }
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.error = e.message;
    } finally {
      this.busy = false;
    }
  }
}
