import { Injectable } from '@angular/core';
import { PublicFeathersService } from 'src/app/public/services/public-feathers.service';
import { Audit } from 'src/app/shared/models/audit.model';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable()
export class PublicAuditService extends BaseService<Audit> {
  constructor(publicFeathers: PublicFeathersService) {
    super('public/audit', publicFeathers.app);
  }
}
