import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import QRCode from 'qrcode';
import { Template } from 'src/app/shared/models/template.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomerRegistrationGeneratorService } from 'src/app/shared/services/customer-registration-generator.service';
import { TemplateService } from 'src/app/template/template.service';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';

@Component({
  selector: 'app-share-template-dialog',
  templateUrl: './share-template-dialog.component.html',
  styleUrls: ['./share-template-dialog.component.scss'],
})
export class ShareTemplateDialogComponent implements OnInit {
  public qrcode;
  public generatingPdf: boolean;
  public template: Template;
  private _isShared: boolean;
  public get isShared(): boolean {
    return this._isShared;
  }
  public set isShared(value: boolean) {
    this._isShared = value;
    if (this._isShared) {
      this.template.shared = {
        url: `${environment.publicWebAppUrl}/${btoa(
          `${v4()}/${this.template._id}/${v4()}`
        )}`,
      };
      QRCode.toDataURL(this.template.shared.url).then(
        (qrcodeData) => (this.qrcode = qrcodeData)
      );
    } else {
      this.template.shared = null;
      this.qrcode = null;
    }
  }
  private _hasMaxDate: boolean;
  public get hasMaxDate(): boolean {
    return this._hasMaxDate;
  }
  public set hasMaxDate(value: boolean) {
    this._hasMaxDate = value;
    if (!this._hasMaxDate) {
      this.template.shared.maxDate = null;
    }
  }
  private _hasMaxCount: boolean;
  public get hasMaxCount(): boolean {
    return this._hasMaxCount;
  }
  public set hasMaxCount(value: boolean) {
    this._hasMaxCount = value;
    if (!this._hasMaxCount) {
      this.template.shared.maxCount = null;
    }
  }
  public minMaxDate = moment().add(1, 'day');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { template: Template },
    public dialogRef: MatDialogRef<ShareTemplateDialogComponent>,
    private _templateService: TemplateService,
    private _customerRegistrationGenerator: CustomerRegistrationGeneratorService,
    private _auth: AuthService
  ) {
    this.template = data.template;
    this._isShared = this.template.shared ? true : false;
    if (this.isShared) {
      this._hasMaxCount = this.template.shared.maxCount ? true : false;
      this._hasMaxDate = this.template.shared.maxDate ? true : false;
      QRCode.toDataURL(this.template.shared.url).then(
        (qrcodeData) => (this.qrcode = qrcodeData)
      );
    }
  }

  ngOnInit() {}

  async save() {
    await this._templateService.patch(this.template._id, {
      shared: this.template.shared,
    });
    this.dialogRef.close(this.template);
  }

  public async generateCustomerRegistration() {
    this.generatingPdf = true;
    const response = ((await this._customerRegistrationGenerator.create({
      name: { value: this._auth.currentUser.tenant.name, x: 30, y: 791 },
      qrcodeData: {
        value: this.template.shared.url,
        x: 177,
        y: 110,
      },
    })) as unknown) as ArrayBuffer;
    const blob = new Blob([response], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
    this.generatingPdf = false;
  }
}
