<p class="title">{{ node.title }}</p>

<div class="wrapper">
  <button mat-icon-button (click)="showNote = !showNote" class="note-toggle-button"><mat-icon>create</mat-icon></button>
  <button
    mat-stroked-button
    class="question-button"
    [ngStyle]="{ color: button.borderColor, 'background-color': button.backgroundColor }"
    (click)="toggle(i)"
    *ngFor="let button of node.selectables; let i = index"
  >
    <span>{{ button.label }}</span>
  </button>
  <mat-form-field *ngIf="showNote">
    <mat-label stacked>Bemerkung</mat-label>
    <textarea matInput [(ngModel)]="note" mat-autosize></textarea>
  </mat-form-field>
</div>
<p *ngIf="node.description">
  <small text-wrap class="small">{{ node.description }}</small>
</p>
