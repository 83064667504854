import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '@feathersjs/feathers';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReplaySubject } from 'rxjs';
import { CurrentUser } from 'src/app/shared/models/current-user.model';
import { TrackerService } from 'src/app/shared/services/tracker.service';
import { environment } from 'src/environments/environment';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _app: Application;
  private _isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this._isAuthenticatedSubject.asObservable();

  private _currentUser: CurrentUser;
  public get currentUser(): CurrentUser {
    return this._currentUser;
  }

  constructor(
    feathers: FeathersService,
    private _tracker: TrackerService,
    private oauthService: OAuthService,
    private _router: Router
  ) {
    oauthService.configure(environment.authConfig);
    oauthService.setupAutomaticSilentRefresh();
    this._app = feathers.app;
    if (window.location.host !== 'p.swodoc.com') {
      this.oauthLogin();
    }
  }

  public async oauthLogin() {
    try {
      await this.oauthService.loadDiscoveryDocumentAndLogin();
      const accessToken = this.oauthService.getAccessToken();
      if (!accessToken) {
        throw new Error('no access Token');
      }
      const tokenUserInfo = await this.oauthService.loadUserProfile();
      if (
        (tokenUserInfo?.registrations ?? []).includes(
          environment.authConfig.clientId
        ) === false
      ) {
        await this.logout();
        return;
      }
      await this.loginWithJwt(accessToken);
    } catch (e) {
      this._isAuthenticatedSubject.next(false);
    }
  }

  public async register(
    firstName: string,
    lastName: string,
    email: string,
    organization: string,
    password: string
  ) {
    this._tracker.trackRegister();
    return this._app
      .service('user')
      .create({ firstName, lastName, email, organization, password });
  }

  private async loginWithJwt(jwt: string) {
    const options = {
      strategy: 'jwt',
      accessToken: jwt,
    };
    const response = await this._app.authenticate(options);
    this._tracker.trackLogin();
    await this.authSuccessfull(response.user);
  }

  /* deprecated */
  public async login(email: string, password: string) {
    const options = {
      strategy: 'local',
      email: email.toLowerCase(),
      password,
    };
    const response = await this._app.authenticate(options);
    this._tracker.trackLogin();
    await this.authSuccessfull(response.user);
  }

  public async verify(verifyToken: string) {
    return this._app.service('authManagement').create({
      action: 'verifySignupLong',
      value: verifyToken,
    });
  }

  public async logout() {
    this._isAuthenticatedSubject.next(false);
    this._tracker.trackLogout();
    await this._app.logout();
    this.oauthService.logoutUrl = environment.authConfig.logoutUrl;
    this.oauthService.logOut();
  }

  public async getToken() {
    return this._app.authentication.getAccessToken();
  }

  private async authSuccessfull(user: CurrentUser) {
    this._currentUser = user;
    this._isAuthenticatedSubject.next(true);
    this._tracker.setUser(user);
  }
}
