import { Component, OnInit } from '@angular/core';
import { CurrentUser } from 'src/app/shared/models/current-user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TrackerService } from 'src/app/shared/services/tracker.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentUser: CurrentUser;

  constructor(private _auth: AuthService, private _tracker: TrackerService) {}

  ngOnInit() {
    this.currentUser = this._auth.currentUser;
    this._tracker.trackHome();
  }
}
