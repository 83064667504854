<mat-dialog-content>
  <app-xlsx-to-table
    (tableData)="setData($event)"
    [validationFunction]="validationFunction"
  ></app-xlsx-to-table>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    color="accent"
    (click)="dialogRef.close()"
    [disabled]="busy"
  >
    <mat-icon>cancel</mat-icon>Abbrechen
  </button>
  <button
    mat-button
    color="primary"
    (click)="import()"
    [disabled]="!users || users.length === 0 || busy || importFailed"
    style="display: flex;"
  >
    <mat-icon>save_alt</mat-icon
    >{{ importCount ? '(' + importCount + ') ' : '' }} Benutzer importieren
  </button>
</mat-dialog-actions>
