<mat-sidenav-container hasBackdrop="false">

  <mat-sidenav #sidenav [mode]="mode" [position]="position" fixedInViewport="true" [fixedTopGap]="fixedTopGap" disableClose="true"
    [ngClass]="{'fullscreen':fullscreen}">
    <ng-content select="[detail]"></ng-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content select="[body]"></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
