import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AttachmentMetadata } from 'src/app/shared/models/attachment-metadata.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentBinaryService {
  private _url = `${environment.apiUrl}/attachment`;

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
    private _feathersAppProvider: FeathersService
  ) {}

  public async download(id: string, type: string) {
    return new Promise(async (resolve) => {
      const token = await this._auth.getToken();
      this._http
        .get(`${this._url}/${id}`, {
          headers: { Authorization: token },
        })
        .subscribe((data: any) => {
          const blob = new Blob([new Uint8Array(data.data)], { type });
          resolve(blob);
        });
    });
  }

  public async get(id: string, type: string, returnValue: string = 'base64') {
    return new Promise((resolve, reject) => {
      const dataTransformHandler = (data?) => {
        if (data) {
          if (returnValue === 'blobUrl') {
            return resolve(URL.createObjectURL(data));
          }
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            resolve(reader.result);
          };
        } else {
          resolve();
        }
      };
      this.download(id, type)
        .then(dataTransformHandler)
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(
    image: string,
    meta: AttachmentMetadata = {} as any
  ): Observable<{ loading: boolean; progress: number; data?: { id: string } }> {
    // FormData.get() not supported in Safari
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/get
    // const file = data.get('file').toString();
    const b64data = image.substring(image.indexOf(',') + 1);
    const mime = this.base64MimeType(image);
    const blob = this.b64toBlob(b64data, mime);
    const filename = this.buildFileName(meta);
    const form = this.buildFormData(blob, filename, meta);
    return this.uploadAttachment(form).asObservable();
  }

  public async delete(id: string) {
    const token = await this._auth.getToken();
    return this._http
      .delete(`${this._url}/${id}`, {
        headers: { Authorization: token },
      })
      .pipe(take(1))
      .subscribe();
  }

  private buildFormData(blob: any, filename: string, meta: {}): FormData {
    const form = new FormData();
    form.append('file', blob, filename);
    for (const key of Object.keys(meta)) {
      form.append(key, meta[key]);
    }
    return form;
  }

  private buildFileName(meta: {}) {
    return `${meta['name'] ? meta['name'] : 'unnamed'}.jpg`;
  }

  private b64toBlob(
    b64Data,
    contentType: string = '',
    sliceSize: number = 512
  ) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = [slice.length];
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  private base64MimeType(encoded) {
    let result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  private uploadAttachment(
    data: FormData
  ): Subject<{ loading: boolean; progress: number; data?: { id: string } }> {
    const subject = new Subject<{
      loading: boolean;
      progress: number;
      data?: { id: string };
    }>();
    this._auth.getToken().then((token) => {
      this._http
        .post(this._url, data, {
          reportProgress: true,
          observe: 'events',
          responseType: 'json',
          headers: { Authorization: token },
        })
        .subscribe(
          async (event: any) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                subject.next({ loading: true, progress: event.loaded });
                break;
              case HttpEventType.Response:
                if (event.ok) {
                  subject.next({
                    loading: false,
                    progress: 100,
                    data: { id: event.body._id },
                  });
                  subject.complete();
                }
            }
          },
          (err) => {
            subject.error(err);
          }
        );
    });
    return subject;
  }
}
