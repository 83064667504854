import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { ImportUsersDialogComponent } from './import-users-dialog/import-users-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserMoreMenuComponent } from './user-more-menu/user-more-menu.component';

const userRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: 'user', component: UserListComponent, canActivate: [AuthGuard] },
]);

@NgModule({
  imports: [
    userRouting,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    SharedModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatDividerModule,
  ],
  entryComponents: [ResetPasswordDialogComponent, ImportUsersDialogComponent],
  declarations: [
    UserListComponent,
    UserDetailComponent,
    ResetPasswordDialogComponent,
    UserMoreMenuComponent,
    ImportUsersDialogComponent,
  ],
  exports: [UserListComponent, UserMoreMenuComponent],
})
export class UserModule {}
