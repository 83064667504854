<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="toggleDraft()">
    <mat-icon>{{ template.draft ? 'add_to_home_screen' : 'drafts' }}</mat-icon
    >{{ template.draft ? 'Live' : 'Entwurf' }}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="edit()">
    <mat-icon>edit</mat-icon>Bearbeiten
  </button>
  <button mat-menu-item (click)="share()">
    <mat-icon>share</mat-icon>Veröffentlichen
  </button>
  <button mat-menu-item (click)="duplicate()">
    <mat-icon>file_copy</mat-icon>Duplizieren
  </button>
  <button mat-menu-item (click)="delete()">
    <mat-icon>delete</mat-icon>Löschen
  </button>
</mat-menu>

<button
  mat-icon-button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="appMenu"
  (click)="openMenu($event)"
>
  <mat-icon>more_vert</mat-icon>
</button>
