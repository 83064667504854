import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExportDetailComponent } from './export-detail/export-detail.component';
import { ScheduleDialogComponent } from './export-detail/schedule.dialog.component';
import { ExportListComponent } from './export-list/export-list.component';

const exportRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: 'export', component: ExportListComponent, canActivate: [AuthGuard] },
  {
    path: 'export/:id',
    component: ExportDetailComponent,
    canActivate: [AuthGuard],
  },
]);

@NgModule({
  imports: [
    exportRouting,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTabsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDatepickerModule,
    MomentModule,
  ],
  declarations: [
    ExportListComponent,
    ExportDetailComponent,
    ScheduleDialogComponent,
  ],
})
export class ExportModule {}
