import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralHelper } from 'src/app/shared/helper/general.helper';

import { ImportUser } from '../../shared/models/import.user.model';
import { Tenant } from '../../shared/models/tenant.model';
import { User } from '../../shared/models/user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-import-users-dialog',
  templateUrl: './import-users-dialog.component.html',
  styleUrls: ['./import-users-dialog.component.scss']
})
export class ImportUsersDialogComponent implements OnInit {
  public busy = false;
  public importFailed = false;
  public users: ImportUser[];
  public importCount: number;
  private allUsers: User[];

  constructor(
    public dialogRef: MatDialogRef<ImportUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tenant: Tenant },
    public userService: UserService
  ) {}

  ngOnInit() {
    this.loadAllUsers();
  }

  private async loadAllUsers() {
    this.allUsers = (await this.userService.find()) as User[];
  }

  public setData(users: ImportUser[]) {
    this.users = users;
    this.importCount = this.users.length;
  }

  public async import() {
    this.busy = true;
    const usersToImport = this.users.map(m => {
      const user: User = Object.assign(new User(), m);
      user.tenantId = this.data.tenant._id;
      delete (user as any).ignore;
      return user;
    });
    await Promise.all(
      usersToImport.map(async user => {
        await this.userService.create(user).catch(e => {
          const origUser: ImportUser = this.users.find(
            f => f.email === user.email
          );
          origUser.failed = true;
          origUser.failedText = e.message;
        });
      })
    );
    this.importFailed = this.users.filter(f => f.failed).length > 0;
    this.busy = false;
    if (!this.importFailed) {
      this.dialogRef.close();
    }
  }

  public validationFunction(data: ImportUser[]) {
    return data.map(item => {
      item.ignore =
        !item.email ||
        !GeneralHelper.validateEmail(item.email) ||
        this.allUsers.find(f => f.email === item.email) !== undefined;
      return item;
    });
  }
}
