import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicAttachmentBinaryService {
  private _url = `${environment.publicApiUrl}/public/attachment`;

  constructor(private _http: HttpClient) {}

  public async download(id: string, type: string) {
    return new Promise(async (resolve) => {
      this._http
        .get(`${this._url}/${id}`, {
          headers: {
            Authorization:
              'Basic cVRMJVJOTVhjVTMzRWFSTVZebSE6cHVjVzQjeFJGdXBjbXlLJm41bjk=',
          },
        })
        .subscribe((data: any) => {
          const blob = new Blob([new Uint8Array(data.data)], { type });
          resolve(blob);
        });
    });
  }

  public async get(id: string, type: string, returnValue: string = 'base64') {
    return new Promise((resolve, reject) => {
      const dataTransformHandler = (data?) => {
        if (data) {
          if (returnValue === 'blobUrl') {
            return resolve(URL.createObjectURL(data));
          }
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            resolve(reader.result);
          };
        } else {
          resolve();
        }
      };
      this.download(id, type)
        .then(dataTransformHandler)
        .catch((err) => {
          reject(err);
        });
    });
  }
}
