import { Injectable } from '@angular/core';

import { User } from '../shared/models/user.model';
import { BaseService } from '../shared/services/base.service';
import { FeathersService } from '../shared/services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User> {
  constructor(feathers: FeathersService) {
    super('user', feathers.app);
  }
}
