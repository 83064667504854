<h1 mat-dialog-title>Passwort für Benutzer
  <b>{{data.firstName}} {{data.lastName}}</b> zurücksetzen?</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input [type]="showPassword?'text':'password'" matInput [(ngModel)]="newPassword" [disabled]="busy" placeholder="Neues Passwort">
    <mat-icon class="far" [ngClass]="showPassword?'fa-eye':'fa-eye-slash'" matSuffix (click)="showPassword=!showPassword"></mat-icon>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="resetPassword()" cdkFocusInitial color="primary" [disabled]="busy || !newPassword">Zurücksetzen</button>
  <button mat-button color="accent" (click)="close()" [disabled]="busy">Abbrechen</button>
</div>
