import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BuyAuthGuard } from 'src/app/buy-credits/buy-auth-guard.service';
import { BuyCreditsItemComponent } from './buy-credits-item/buy-credits-item.component';
import { BuyCreditsComponent } from './buy-credits.component';

const buyCreditsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [{ path: 'buy', component: BuyCreditsComponent, canActivate: [BuyAuthGuard] }]
);

@NgModule({
  declarations: [BuyCreditsComponent, BuyCreditsItemComponent],
  imports: [
    buyCreditsRouting,
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class BuyCreditsModule {}
