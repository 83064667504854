import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tenant-tile',
  templateUrl: './tenant-tile.component.html',
  styleUrls: ['./tenant-tile.component.scss'],
})
export class TenantTileComponent implements OnInit {
  @Input() tenantForm: FormGroup;
  @Output() saveTenant = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
