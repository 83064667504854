import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-tile',
  templateUrl: './security-tile.component.html',
  styleUrls: ['./security-tile.component.scss']
})
export class SecurityTileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
