import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { FullscreenService } from '../../services/fullscreen.service';

@Component({
  selector: 'app-detail-drawer',
  templateUrl: './detail-drawer.component.html',
  styleUrls: ['./detail-drawer.component.scss'],
})
export class DetailDrawerComponent implements OnInit, OnDestroy {
  @Input()
  mode = 'over';
  @Input()
  position = 'end';
  @Input()
  fixedTopGap = 45;
  @ViewChild('sidenav', { static: true })
  sideNav: MatSidenav;
  @Output()
  closedStart = new EventEmitter();

  private _fullscreenSubscription: Subscription;
  public fullscreen: boolean;

  constructor(fullscreenService: FullscreenService) {
    this._fullscreenSubscription = fullscreenService.fullscreen.subscribe(fullscreen => {
      this.fullscreen = fullscreen;
      this.fixedTopGap = fullscreen ? 0 : this.fixedTopGap;
    });
  }
  ngOnInit() {
    this.sideNav.closedStart.subscribe(() => this.closedStart.emit());
  }

  ngOnDestroy() {
    this._fullscreenSubscription.unsubscribe();
  }

  open() {
    this.sideNav.open();
  }

  close() {
    this.sideNav.close();
  }
}
