import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { User } from '../../shared/models/user.model';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'app-user-more-menu',
  templateUrl: './user-more-menu.component.html',
  styleUrls: ['./user-more-menu.component.scss'],
})
export class UserMoreMenuComponent implements OnInit {
  @Input() user: User;

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {}

  ngOnInit() {}

  openMenu(event: Event) {
    event.stopPropagation();
  }

  showResetPasswordDialog() {
    const dialogRef = this._dialog.open(ResetPasswordDialogComponent, {
      width: '500px',
      data: { userId: this.user._id, firstName: this.user.firstName, lastName: this.user.lastName },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this._snackBar.open(`Passwort zurücksetzen ${result ? 'erfolgreich' : 'fehlgeschlagen'}`, undefined, {
          duration: 2000,
        });
      }
    });
  }
}
