import { Component, OnInit, OnDestroy } from '@angular/core';

import { BreadCrumb } from '../../models/breadcrumb.model';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { FullscreenService } from '../../services/fullscreen.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  breadcrumbs: BreadCrumb[];
  private _breadcrumbSubscription: Subscription;

  constructor(private _breadcrumbService: BreadcrumbService, public fullscreenService: FullscreenService) {}

  ngOnInit() {
    this._breadcrumbSubscription = this._breadcrumbService.currentBreadcrumbs.subscribe(
      breadcrumbs => (this.breadcrumbs = breadcrumbs)
    );
  }

  ngOnDestroy() {
    this._breadcrumbSubscription.unsubscribe();
  }
}
