import {
  Application,
  Id,
  NullableId,
  Params,
  Service,
  ServiceMethods,
} from '@feathersjs/feathers';
import { v4 } from 'uuid';
import { ServiceOptions } from '../models/service.options.model';

export class BaseService<T> implements ServiceMethods<T> {
  protected readonly _service: Service<T>;
  public readonly cache: T[] = [];
  private _fetchingPromise: Promise<T[] | { total: number; items: T[] }>;
  constructor(serviceName: string, app: Application) {
    this._service = app.service(serviceName);
    this._service.removeAllListeners();
  }

  async find(params?: Params, options?: ServiceOptions): Promise<any> {
    if (this._fetchingPromise) {
      return this._fetchingPromise;
    }
    this._fetchingPromise = new Promise<T[] | { total: number; items: T[] }>(
      async (_resolve, _reject) => {
        try {
          const result: any = await this._service.find(params);
          const items =
            result.data && result.total !== undefined ? result.data : result;
          if (options && options.noCaching) {
            if (options && options.returnTotal) {
              _resolve({ total: result.total, items });
            } else {
              _resolve(items);
            }
            return;
          }
          this.cache.splice(0, this.cache.length);
          this.cache.push(...items);
          if (options && options.returnTotal) {
            _resolve({ total: result.total, items: this.cache });
          } else {
            _resolve(this.cache);
          }
        } catch (e) {
          _reject(e);
        }
      }
    );
    const val = await this._fetchingPromise;
    this._fetchingPromise = undefined;
    return val;
  }

  async count(params?: Params): Promise<number> {
    if (params && params.query) {
      params.query['$limit'] = 0;
    }
    return new Promise<number>(async (_resolve, reject) => {
      try {
        const result: any = await this._service.find(params);
        const count =
          result.data && result.total !== undefined
            ? result.total
            : result.length;
        _resolve(count);
      } catch (e) {
        reject(e);
      }
    });
  }

  async get(id: Id, params?: Params, options?: ServiceOptions): Promise<T> {
    if (options && options.noCaching) {
      return this._service.get(id, params);
    }
    const item = this.cache.find((f: any) => f._id === id || f.id === id);
    if (item) {
      return Promise.resolve(item);
    }
    return this._service.get(id, params);
  }

  async create(
    data: Partial<T> | Array<Partial<T>>,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T | T[]> {
    try {
      if (!data.hasOwnProperty('_id')) {
        data['_id'] = v4();
      }
      const item = await this._service.create(data, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      this.cache.push(item as any);
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async update(
    id: NullableId,
    data: T,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T> {
    try {
      const item = await this._service.update(id, data, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      const index = this.cache.findIndex(
        (f) => (f as any)._id === (item as any)._id
      );
      if (index > -1) {
        this.cache[index] = item;
      }
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async patch(
    id: NullableId,
    data: Partial<T>,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T> {
    try {
      const item = await this._service.patch(id, data, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      const index = this.cache.findIndex(
        (f) => (f as any)._id === (item as any)._id
      );
      if (index > -1) {
        this.cache[index] = item;
      }
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async remove(
    id: NullableId,
    params?: Params,
    options?: ServiceOptions
  ): Promise<T> {
    try {
      const item = await this._service.remove(id, params);
      if (options && options.noCaching) {
        return Promise.resolve(item);
      }
      const index = this.cache.findIndex((f) => (f as any)._id === id);
      this.cache.splice(index, 1);
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public on(event: string, listener: (...args: any[]) => void) {
    this._service.on(event, listener);
  }
}
