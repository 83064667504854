import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { AuthGuard } from 'src/app/shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { CategoryComponent } from './category/category.component';
import { TemplateCategoryPipe } from './category/category.pipe';
import { CopyTemplateDialogComponent } from './copy-template-dialog/copy-template-dialog.component';
import { CreateEditTemplateDialogComponent } from './create-edit-template-dialog/create-edit-template-dialog.component';
import { ImportTemplateDialogComponent } from './import-template-dialog/import-template-dialog.component';
import { ImportTemplateComponent } from './import-template/import-template.component';
import { ShareTemplateDialogComponent } from './share-template-dialog/share-template-dialog.component';
import { TemplateBasicInfoFormComponent } from './template-basic-info-form/template-basic-info-form.component';
import { TemplateDetailComponent } from './template-detail/template-detail.component';
import { TemplateReportComponent } from './template-detail/template-report/template-report.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplateMoreMenuComponent } from './template-more-menu/template-more-menu.component';

const templateRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [
    {
      path: 'template',
      component: TemplateListComponent,
      canActivate: [AuthGuard],
    },
  ]
);

@NgModule({
  imports: [
    templateRouting,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTabsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MomentModule,
  ],
  declarations: [
    TemplateListComponent,
    TemplateDetailComponent,
    TemplateCategoryPipe,
    TemplateMoreMenuComponent,
    ImportTemplateDialogComponent,
    ImportTemplateComponent,
    TemplateBasicInfoFormComponent,
    CreateEditTemplateDialogComponent,
    CategoryComponent,
    CopyTemplateDialogComponent,
    TemplateReportComponent,
    ShareTemplateDialogComponent,
  ],
  entryComponents: [
    ImportTemplateDialogComponent,
    CreateEditTemplateDialogComponent,
    CopyTemplateDialogComponent,
    ShareTemplateDialogComponent,
  ],
  exports: [TemplateListComponent, ImportTemplateComponent],
})
export class TemplateModule {}
