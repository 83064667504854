import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-checkbox',
  templateUrl: 'swodoc-node-checkbox.html',
  styleUrls: ['swodoc-node-checkbox.scss'],
})
export class SwodocNodeCheckboxComponent {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  get value(): boolean {
    if (
      this.node.selectables &&
      this.node.selectables.length > 0 &&
      this.node.selectables[0].selected
    ) {
      return this.node.selectables[0].selected;
    }
    return false;
  }
  set value(value: boolean) {
    this.node.selectables = this.node.selectables || [];
    if (this.node.selectables.length === 0) {
      this.node.selectables.push({ selected: value });
    } else {
      this.node.selectables[0] = { selected: value };
    }
    this.changed.emit(this.node);
  }
}
