import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TrackerService {
  tracker: amplitude.AmplitudeClient;

  constructor() {
    this.tracker = amplitude.getInstance();
    this.tracker.init(environment.amplitude.apiKey);
  }

  private logEvent(name: string, params: any = null) {
    if (!environment.production) {
      return;
    }
    setTimeout(() => {
      try {
        this.tracker.logEvent(name, params);
      } catch (err) {
        console.error('tracking error', err);
      }
    });
  }

  public setUser(user) {
    if (user) {
      this.tracker.setUserId(user._id);
      this.tracker.setUserProperties({
        email: user.email,
      });
    }
  }

  public trackHome() {
    this.logEvent('general:show-home');
  }

  public trackLogin() {
    this.logEvent('general:login');
  }

  public trackLogout() {
    this.logEvent('general:logout');
  }

  public trackRegister() {
    this.logEvent('general:register');
  }
}
