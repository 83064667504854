<div class="page">
  <!--<div class="filter"> Filter</div> -->
  <div class="content">
    <div class="button-wrapper">
      <button mat-button (click)="exportToXLSX()" color="primary" [disabled]="drawerOpen">
        <mat-icon class="fa fa-file-excel"></mat-icon> Excel herunterladen
      </button>
      <button mat-button (click)="scheduleReport()" color="primary" [disabled]="drawerOpen">
        <mat-icon class="fa fa-calendar"></mat-icon> Schedule Report
      </button>
      <div *ngIf="schedule">Nächster Lauf: {{schedule.info.nextActionTimes[0] | amCalendar}}</div>
    </div>

    <app-spinner *ngIf="showSpinner"></app-spinner>
    <div style="display: flex; flex-direction: column; height: 100%; width: 100%;">
      <div style="flex: 1; width: 100%; height: 100%; display: flex; flex-direction: column;"
        [style.display]="showSpinner ? 'none' : 'inherit'">
        <div style="flex: 1 ;overflow: scroll;">
          <mat-table [dataSource]="dataSource" style="height: 100%;width: 15000px; ">
            <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{ disCol }}">
              <ng-container *ngIf="disCol === '_sel'">
                <mat-header-cell *matHeaderCellDef>
                  <app-item-selector (selectedChange)="selectAll()"></app-item-selector>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="max-width: 60px">
                  <app-item-selector [(selected)]="element.selected"></app-item-selector>
                </mat-cell>
              </ng-container>
              <ng-container *ngIf="disCol === '_export'">
                <mat-header-cell *matHeaderCellDef style="max-width: 75px">
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" style="max-width: 90px">
                  <button mat-button mat-icon-button (click)="exportSinglePDF(i)" color="primary"
                    *ngIf="template?.report">
                    <mat-icon class="fa fa-file-pdf"></mat-icon>
                  </button>
                  <button mat-button mat-icon-button (click)="exportSingleXLSX(i)" color="primary">
                    <mat-icon class="fa fa-file-excel"></mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container *ngIf="disCol !== '_sel'">
                <mat-header-cell *matHeaderCellDef class="column">{{
                  disCol
                  }}</mat-header-cell>
                <mat-cell *matCellDef="let element" style="font-size: 11px;margin: 5px 0 5px 0"
                  [innerHtml]="element[disCol]"></mat-cell>
              </ng-container>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          </mat-table>
        </div>
        <mat-paginator style="height: 100px;" #paginator [length]="resultLength" [pageSize]="20"
          [pageSizeOptions]="[5, 10, 20, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>