<mat-card>
  <mat-card-header>
    <div mat-card-avatar><mat-icon>credit_card</mat-icon></div>
    <mat-card-title>Deine Credits</mat-card-title>
    <mat-card-subtitle class="credits">{{ credits }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions *ngIf="isAdmin">
    <a
      mat-stroked-button
      class="full-width"
      color="primary"
      [routerLink]="['/', 'buy']"
    >
      Credits kaufen
    </a>
  </mat-card-actions>
</mat-card>
