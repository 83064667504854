import { Injectable } from '@angular/core';
import authentication from '@feathersjs/authentication-client';
import feathers, { Application } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeathersService {
  private _app: Application;
  public get app(): Application {
    return this._app;
  }
  constructor() {
    this._app = feathers();
    const socket = io(environment.apiUrl, {
      transports: ['websocket'],
      upgrade: false,
      forceNew: true,
    });
    this._app.configure(socketio(socket, { timeout: 10000 }));
    this._app.configure(
      authentication({
        storageKey: 'authorizationData',
      })
    );
  }
}
