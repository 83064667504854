import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { DetailDrawerComponent } from '../../shared/layout/detail-drawer/detail-drawer.component';
import { TableComponent } from '../../shared/layout/table/table.component';
import { Tenant } from '../../shared/models/tenant.model';
import { User } from '../../shared/models/user.model';
import { ImportUsersDialogComponent } from '../import-users-dialog/import-users-dialog.component';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  @ViewChild('drawer', { static: true })
  drawer: DetailDrawerComponent;
  @ViewChild('table', { static: true })
  table: TableComponent;

  private _dataSourceSubject = new Subject<User[]>();
  public dataSource = this._dataSourceSubject.asObservable();

  public displayedColumns = ['avatar', 'firstName', 'lastName', 'email', 'more'];
  public selectedUser: User = new User();
  public tenant: Tenant;
  public showSpinner: boolean;
  public drawerOpen: boolean;
  public filter: string;

  constructor(private _userService: UserService, private _dialog: MatDialog, private _domsanitizer: DomSanitizer) {}

  async loadUsers() {
    this.showSpinner = true;
    const users = (await this._userService.find()) as User[];
    this._dataSourceSubject.next(users);
    this.showSpinner = false;
  }

  ngOnInit() {
    this.loadUsers();
    this.drawer.closedStart.subscribe(() => {
      this.table.unselectAll();
    });
  }

  select(user: User) {
    this.selectedUser = { ...user };
    this.drawer.open();
  }

  add() {
    this.selectedUser = new User();
    this.drawer.open();
  }

  import() {
    this._dialog
      .open(ImportUsersDialogComponent, {
        width: '800px',
        maxHeight: '800px',
        disableClose: true,
        autoFocus: false,
        // data: { tenant: this.tenant },
      })
      .afterClosed()
      .subscribe(() => {
        this.refresh();
      });
  }

  updated(item) {
    this.refresh();
  }

  private refresh() {
    this._dataSourceSubject.next(this._userService.cache);
  }
}
