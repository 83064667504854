import { Pipe, PipeTransform } from '@angular/core';

import { StringHelper } from '../helper/string.helper';

@Pipe({
  name: 'upperFirst',
})
export class UpperFirstPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return StringHelper.capitalizeFirstLetter(value);
  }
}
