import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import * as moment from 'moment';
import { BuyCreditsModule } from 'src/app/buy-credits/buy-credits.module';
import { ExportModule } from 'src/app/export/export.module';
import { GroupModule } from 'src/app/group/group.module';
import { HomeModule } from 'src/app/home/home.module';
import { ProfileModule } from 'src/app/profile/profile.module';
import { PublicModule } from 'src/app/public/public.module';
import { RegisterModule } from 'src/app/register/register.module';
import { SentryErrorHandler } from 'src/app/sentry-error-handler';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplateModule } from 'src/app/template/template.module';
import { TenantModule } from 'src/app/tenant/tenant.module';
import { UserModule } from 'src/app/user/user.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { LoginModule } from './login/login.module';

moment.updateLocale('de', {});
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatMomentDateModule,
    LoginModule,
    RegisterModule,
    SharedModule,
    HomeModule,
    UserModule,
    GroupModule,
    TemplateModule,
    ExportModule,
    BuyCreditsModule,
    ProfileModule,
    PublicModule,
    TenantModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
