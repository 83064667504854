<mat-nav-list class="app-navigation">
  <img class="logo" src="assets/logo.svg" />
  <img class="avatar" src="assets/avatar.svg" />
  <div class="username">
    <a #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="currentUserMenu">
      {{ auth?.currentUser?.firstName }}
      {{ auth?.currentUser?.lastName }}
    </a>
    <div class="tenant">{{ auth?.currentUser?.tenant?.name }}</div>
  </div>
  <mat-divider class="strong"></mat-divider>
  <a
    mat-list-item
    [routerLink]="['/']"
    [ngClass]="{ active: currentNav === 'home' }"
  >
    <mat-icon matListIcon>home</mat-icon>Home
  </a>
  <mat-divider></mat-divider>

  <a mat-list-item [routerLink]="['template']" routerLinkActive="active">
    <mat-icon>important_devices</mat-icon> Vorlagen
  </a>
  <!-- <a mat-list-item [routerLink]="['inventory']" routerLinkActive="active">
    <mat-icon>input</mat-icon> Inventar
  </a> -->
  <a mat-list-item [routerLink]="['export']" routerLinkActive="active">
    <mat-icon>cloud_download</mat-icon> Daten
  </a>
  <mat-divider class="strong"></mat-divider>
  <!-- <a mat-list-item [routerLink]="['user']" routerLinkActive="active">
    <mat-icon>person</mat-icon> Benutzer
  </a> -->
  <a
    mat-list-item
    [routerLink]="['tenant']"
    routerLinkActive="active"
    *ngIf="isAdmin"
  >
    <mat-icon>people</mat-icon> Admin
  </a>
  <div class="spacer"></div>
  <mat-divider class="strong"></mat-divider>
  <div class="footer">v {{ buildNumber }}</div>
  <mat-menu #currentUserMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item [routerLink]="['profile']">
      <mat-icon>person</mat-icon>Profil
    </button>
    <button mat-menu-item [routerLink]="['tenant']" *ngIf="isAdmin">
      <mat-icon>group</mat-icon>Admin
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>Logout
    </button>
  </mat-menu>
</mat-nav-list>
