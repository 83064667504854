<mat-card>
  <mat-card-header
    ><div mat-card-avatar><mat-icon>person</mat-icon></div>
    <mat-card-title>User</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list dense>
      <mat-list-item *ngFor="let user of users">
        <app-user-avatar matListAvatar [avatar]="user.avatar"></app-user-avatar>
        <h3 matLine>{{ user.firstName }} {{ user.lastName }}</h3>
      </mat-list-item>
    </mat-list>
    <a mat-stroked-button class="full-width" color="primary" [routerLink]="['/', 'user']"> Alle anzeigen </a>
  </mat-card-content>
</mat-card>
