import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AddedImage } from 'src/app/audit/nodes/signature/swodoc-node-signature';
import { AuditConditionService } from 'src/app/public/services/audit-condition.service';
import { CreateAuditService } from 'src/app/public/services/create-audit.service';
import { PublicAttachmentService } from 'src/app/public/services/public-attachment.service';
import { PublicAuditService } from 'src/app/public/services/public-audit.service';
import { PublicTemplateService } from 'src/app/public/services/public-template.service';
import { ProgressSpinnerDialogService } from 'src/app/shared/layout/progress-spinner-dialog/progress-spinner-dialog.service';
import { Attachment } from 'src/app/shared/models/attachment';
import { AuditNode } from 'src/app/shared/models/audit-node.model';
import { Audit } from 'src/app/shared/models/audit.model';
import { Template } from 'src/app/shared/models/template.model';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit, AfterViewInit {
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  public template: Template;
  public audit: Audit;
  public dirty: boolean;
  public formulaInputChanged$: Subject<void> = new Subject();
  public backgroundImage: SafeStyle;
  private _id: string;
  private _allNodes: AuditNode[] = [];
  private _watchers = [];
  private imagesToSave: { [auditNodeId: string]: string } = {};
  constructor(
    private _templateService: PublicTemplateService,
    route: ActivatedRoute,
    private _createAuditService: CreateAuditService,
    private _router: Router,
    private _auditService: PublicAuditService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _auditConditionService: AuditConditionService,
    private _progressSpinner: ProgressSpinnerDialogService,
    private _domsanitizer: DomSanitizer,
    private attachmentService: PublicAttachmentService
  ) {
    const { id } = route.snapshot.params;
    this._id = id;
  }

  async ngOnInit() {
    try {
      this.template = await this._templateService.get(this._id);
      if (this.template.headerImageUrl) {
        this.backgroundImage = this._domsanitizer.bypassSecurityTrustStyle(
          `url('${this.template.headerImageUrl}')`
        );
      } else {
        this.backgroundImage = this._domsanitizer.bypassSecurityTrustStyle(
          `url('/assets/card-background/${this.template.category || 0}.jpg')`
        );
      }

      if (this.template) {
        this.audit = await this._createAuditService.createAudit(this.template);
        this.registerConditionWatchers();
        setTimeout(() => this.accordion.openAll());
        this._progressSpinner.hide();
      }
    } catch (e) {
      this._progressSpinner.hide();
      this._router.navigateByUrl('/p/notFound');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this._progressSpinner.show());
  }

  public nodeUpdate(ev) {
    this.evalCondition(ev);
    this.formulaInputChanged$.next();
    this.dirty = true;
  }

  public formulaValueChanged(event) {
    this.evalCondition(event.node, event.value);
  }

  public allMandatoryNodesFilled(): boolean {
    if (!this.audit || this.audit.auditNodes.length === 0) {
      return true;
    }
    for (const section of this.audit.auditNodes) {
      for (const node of section.children) {
        if (this.isMandatoryFilled(node) === false) {
          return false;
        }
      }
    }
    return true;
  }

  // TODO: This should be a pipe!
  public isMandatoryFilled(node: any) {
    if (!node.mandatory || node.inactive) {
      return true;
    }
    switch (node.nodeType_Id) {
      case 'signature':
      case 'image':
        return this.imagesToSave[node.id] !== undefined;
      case 'dropdown':
        return Boolean(node.responses?.[0]?.value);
      default:
        if (
          node.selectables &&
          node.selectables.length > 0 &&
          node.selectables.filter((sel) => {
            return sel.selected;
          }).length > 0
        ) {
          return true;
        }
        if (node.selectables.length === 0) {
          return Boolean(node.responses?.[0]?.value);
        }
        return false;
    }
  }

  public async send() {
    if (this.allMandatoryNodesFilled() === false) {
      return;
    }
    try {
      this._progressSpinner.show();
      await this.saveImages();
      await this._auditService.create(this.audit);
      this._progressSpinner.hide();
      this._router.navigateByUrl('p/completed');
    } catch (e) {
      this._progressSpinner.hide();
      this._router.navigateByUrl('p/error');
    }
  }

  public enqueueImage(addedImage: AddedImage) {
    this.dirty = true;
    this.imagesToSave[addedImage.auditNodeId] = addedImage.data.substring(
      addedImage.data.indexOf(',') + 1
    );
  }

  private async saveImages() {
    const auditNodeIds = Object.keys(this.imagesToSave);
    for (const auditNodeId of auditNodeIds) {
      const attachment = {
        templateId: this.template._id,
        b64data: this.imagesToSave[auditNodeId],
        mime: 'image/jpeg',
        metadata: {
          auditId: this.audit._id,
          auditNodeId,
        },
      };
      await this.attachmentService.create(attachment as unknown as Attachment);
    }
  }
  private registerConditionWatchers() {
    if (this.audit.auditNodes.length === 0) {
      return;
    }
    this._watchers = [];
    this.buildAllNodesArray();
    this.audit.auditNodes.forEach((currentSection) => {
      currentSection.children
        .filter((node) => node.nodeType_Id === 'condition')
        .forEach((node) => {
          const trigger = this._allNodes.find(
            (f) => f.id === node.conditionTriggerId
          );
          if (!trigger) {
            return;
          }
          this._watchers.push({
            conditionTriggerId: trigger.id,
            conditionId: node.id,
            conditions: node.selectables,
          });
          this.evalCondition(trigger);
        });
    });
  }

  private buildAllNodesArray() {
    this.audit.auditNodes.map((currentSection) => {
      if (currentSection.children && currentSection.children.length > 0) {
        currentSection.children.map((node) => {
          this._allNodes.push(node);
        });
      }
    });
  }

  private evalCondition(node, value?) {
    if (!node) {
      return;
    }
    const conditions = this._watchers.filter(
      (f) => f.conditionTriggerId === node.id
    );
    conditions.forEach((condition) => {
      if (this.isConditionInactive(condition.conditionId)) {
        this.setInactive(condition.conditionId);
      } else {
        let conditionedNodes = this._allNodes.filter(
          (f) => f.conditionId === condition.conditionId
        );
        let val =
          (node.selectables &&
            node.selectables.length > 0 &&
            node.nodeType_Id !== 'dropdown') ||
          node.nodeType_Id === 'checkbox'
            ? node.nodeType_Id === 'formula'
              ? value
              : node.selectables
            : node.responses && node.responses.length > 0
            ? node.responses[0].value
            : undefined;
        if (node.nodeType_Id === 'inventory') {
          val = node.responses.length ? node.responses[0].selectedItem : '';
        }

        conditionedNodes = conditionedNodes.map((i) => {
          i.inactive = !this._auditConditionService.conditionEval(
            condition.conditions,
            val
          );
          return i;
        });
      }
    });
    this._changeDetectionRef.detectChanges();
  }

  private isConditionInactive(conditionId) {
    return this._allNodes.find((f) => f.id === conditionId).inactive;
  }

  private setInactive(conditionId) {
    this._allNodes
      .filter((f) => f.conditionId === conditionId)
      .map((i) => (i.inactive = true));
  }
}
