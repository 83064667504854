import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-spinner-dialog',
  template: '<mat-spinner></mat-spinner>',
})
export class ProgressSpinnerDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
