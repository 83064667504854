import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-textarea',
  templateUrl: 'swodoc-node-textarea.html',
  styleUrls: ['swodoc-node-textarea.scss'],
})
export class SwodocNodeTextareaComponent {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  get text(): string {
    if (this.node.responses && this.node.responses.length > 0 && this.node.responses[0].value) {
      return this.node.responses[0].value;
    }

    return '';
  }
  set text(value: string) {
    this.node.responses = this.node.responses || [];
    if (this.node.responses.length === 0) {
      this.node.responses.push({ value });
    } else {
      this.node.responses[0] = { value };
    }
    this.changed.emit(this.node);
  }
}
