import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { RegisterComponent } from 'src/app/register/register.component';
import { NoAuthGuard } from 'src/app/shared/services/no-auth-guard.service';

const registerRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [
    {
      path: 'register',
      component: RegisterComponent,
      canActivate: [NoAuthGuard],
    },
  ]
);

@NgModule({
  imports: [
    registerRouting,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatIconModule,
  ],
  declarations: [RegisterComponent],
  exports: [RegisterComponent],
})
export class RegisterModule {}
