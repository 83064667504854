<div class="profile-page">
  <mat-card class="profile-card">
    <mat-card-header
      ><div mat-card-avatar><mat-icon>person</mat-icon></div>
      <mat-card-title>Profil</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form" [formGroup]="profileForm" (submit)="update()">
        <mat-form-field hideRequiredMarker="true">
          <input
            matInput
            placeholder="FirstName"
            name="firstName"
            formControlName="firstName"
            required
          />
        </mat-form-field>
        <mat-form-field hideRequiredMarker="true">
          <input
            matInput
            placeholder="LastName"
            name="lastName"
            formControlName="lastName"
            required
          />
        </mat-form-field>
        <mat-form-field hideRequiredMarker="true">
          <input
            matInput
            placeholder="E-Mail"
            name="email"
            formControlName="email"
            required
          />
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions class="profile-button">
      <button
        mat-raised-button
        (click)="update()"
        color="primary"
        style="width: 100%;"
        [disabled]="profileForm.invalid || busy"
      >
        Speichern
      </button>
    </mat-card-actions>
  </mat-card>
  <mat-card class="password-card">
    <mat-card-header
      ><div mat-card-avatar><mat-icon>vpn_key</mat-icon></div>
      <mat-card-title>Passwort</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="showResetPassword">
      <form class="form" [formGroup]="passwordForm" (submit)="update()">
        <mat-form-field hideRequiredMarker="true">
          <input
            matInput
            placeholder="Password"
            [type]="showPassword ? 'text' : 'password'"
            name="password"
            formControlName="password"
            required
          />
          <mat-icon
            class="far"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            matSuffix
            (click)="showPassword = !showPassword"
          ></mat-icon>
          <mat-error *ngIf="passwordForm.hasError('required', 'password')">
            Please enter your new password
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Confirm Password"
            [type]="showConfirmPassword ? 'text' : 'password'"
            name="confirmPassword"
            formControlName="confirmPassword"
            [errorStateMatcher]="matcher"
          />
          <mat-icon
            class="far"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            matSuffix
            (click)="showConfirmPassword = !showConfirmPassword"
          ></mat-icon>
          <mat-error *ngIf="passwordForm.hasError('notSame')">
            Passwords do not match
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions class="profile-button" *ngIf="showResetPassword">
      <button
        mat-raised-button
        (click)="updatePassword()"
        color="primary"
        style="width: 100%;"
        [disabled]="passwordForm.invalid || busy"
      >
        Speichern
      </button>
    </mat-card-actions>
    <mat-card-actions class="profile-button" *ngIf="!showResetPassword">
      <button
        mat-raised-button
        (click)="showResetPassword = !showResetPassword"
        color="primary"
        style="width: 100%;"
      >
        Passwort ändern
      </button>
    </mat-card-actions>
  </mat-card>
  <mat-card *ngIf="isAdmin">
    <mat-card-header>
      <div mat-card-avatar><mat-icon>group</mat-icon></div>
      <mat-card-title>Organisation</mat-card-title>
    </mat-card-header>
    <mat-card-actions class="profile-button">
      <button
        mat-raised-button
        [routerLink]="['/', 'tenant']"
        color="primary"
        style="width: 100%;"
      >
        Organisation verwalten
      </button>
    </mat-card-actions>
  </mat-card>
</div>
