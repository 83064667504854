import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule)
  }
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
