<mat-dialog-content>

  <app-template-basic-info-form [(template)]="template" (valid)="formIsValid = $event"></app-template-basic-info-form>

  <mat-slide-toggle [(ngModel)]="duplicateReport" [disabled]="!template.report">Report duplizieren</mat-slide-toggle>

  <mat-slide-toggle [(ngModel)]="duplicateMatch" [disabled]="!template.match ">Zuordnungen duplizieren</mat-slide-toggle>

</mat-dialog-content>
<p class="error" *ngIf="error">Fehler: {{error}}</p>
<mat-dialog-actions>
  <mat-progress-bar mode="indeterminate" *ngIf="busy"></mat-progress-bar>
  <button mat-button color="accent" (click)="dialogRef.close()" [disabled]="busy">
    <mat-icon>cancel</mat-icon>Abbrechen</button>
  <button mat-button color="primary" (click)="copy()" [disabled]="!formIsValid || busy || error">
    <mat-icon>save</mat-icon>Duplizieren</button>
</mat-dialog-actions>
