import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  protected _eventSubject = new Subject<{ message: string }>();
  public events = this._eventSubject.asObservable();

  dispatchEvent(event) {
    this._eventSubject.next(event);
  }
}
