import { Injectable } from '@angular/core';
import { Audit } from 'src/app/shared/models/audit.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class AuditService extends BaseService<Audit> {
  constructor(feathers: FeathersService) {
    super('audit', feathers.app);
  }
}
