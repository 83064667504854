import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { Report } from 'src/app/shared/models/report.model';
import { DetailDrawerComponent } from '../../shared/layout/detail-drawer/detail-drawer.component';
import { TableComponent } from '../../shared/layout/table/table.component';
import { Template } from '../../shared/models/template.model';
import { TemplateViewModel } from '../../shared/models/template.view.model';
import { Tenant } from '../../shared/models/tenant.model';
import { ReportService } from '../../shared/services/report.service';
import { CreateEditTemplateDialogComponent } from '../create-edit-template-dialog/create-edit-template-dialog.component';
import { TemplateDetailComponent } from '../template-detail/template-detail.component';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-template',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('table', { static: true })
  table: TableComponent;
  @ViewChild('drawer', { static: true })
  drawer: DetailDrawerComponent;
  @ViewChild('detail', { static: true })
  templateDetail: TemplateDetailComponent;

  public displayedColumns = [
    'public',
    'title',
    'updatedAt',
    'matchReport',
    'more',
  ];

  private _dataSourceSubject = new Subject<Template[]>();
  public dataSource = this._dataSourceSubject.asObservable();

  public selectedTemplate: Template;

  public tenant: Tenant;
  public showSpinner: boolean;
  public filter: string;
  public drawerOpen: boolean;
  private _drawerCloseSubscription: Subscription;
  private _allTemplates: Template[];

  constructor(
    private _templateService: TemplateService,
    private _reportService: ReportService,
    private _dialog: MatDialog
  ) {}

  private async loadTemplates() {
    this.showSpinner = true;
    this._allTemplates = (await this._templateService.find()) as TemplateViewModel[];
    const _reports = (await this._reportService.find(
      { query: { $select: ['_id'] } },
      { noCaching: true }
    )) as Report[];
    if (_reports && _reports.length > 0) {
      this._allTemplates = this._allTemplates.map(
        (template: TemplateViewModel) => {
          template.report = _reports.find((f) => f._id === template._id);
          return template;
        }
      );
    }
    this._dataSourceSubject.next(this._allTemplates);
    this.showSpinner = false;
  }

  ngOnInit() {
    this.loadTemplates();
    this._drawerCloseSubscription = this.drawer.closedStart.subscribe(() => {
      this.table.unselectAll();
      this.update();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._drawerCloseSubscription.unsubscribe();
  }

  select(template: Template) {
    this.openDrawer(0, template);
  }

  openReport(ev: Event, template: Template) {
    ev.stopPropagation();
    this.openDrawer(1, template);
  }

  private openDrawer(index: number, template: Template) {
    this.templateDetail.tabs.selectedIndex = index;
    this.selectedTemplate = { ...template };
    this.drawer.open();
  }

  update() {
    this._dataSourceSubject.next(undefined);
    this.loadTemplates();
  }

  deleted(id: string) {
    if (!id) {
      return;
    }
    this._allTemplates = this._allTemplates.filter((f) => f._id !== id);
    this._dataSourceSubject.next(this._allTemplates);
  }

  add() {
    this._dialog
      .open(CreateEditTemplateDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { tenant: this.tenant },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.update();
          this.select(result);
        }
      });
  }
}
