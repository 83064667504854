import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { BreadCrumb } from '../models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _initialState: BreadCrumb[] = [{ title: 'alberta', path: '' }];
  private _currentBreadcrumbsSubject = new BehaviorSubject<BreadCrumb[]>(this._initialState);
  public currentBreadcrumbs: Observable<BreadCrumb[]> = this._currentBreadcrumbsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor() {}

  push(breadcrumb: BreadCrumb) {
    this._currentBreadcrumbsSubject.next([...this._currentBreadcrumbsSubject.value, breadcrumb]);
  }
}
