import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullscreenService {
  private _fullscreen: boolean;
  private _fullscreenSubject = new Subject<boolean>();
  public fullscreen = this._fullscreenSubject.asObservable();

  toggle() {
    this._fullscreen = !this._fullscreen;
    this._fullscreenSubject.next(this._fullscreen);
  }

  set(value: boolean) {
    this._fullscreen = value;
    this._fullscreenSubject.next(this._fullscreen);
  }
}
