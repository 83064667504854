import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
})
export class InvoiceTableComponent implements OnInit, AfterViewInit {
  @Input() maxDisplay: number;

  private _dataSourceSubject = new Subject<any[]>();
  public dataSource = this._dataSourceSubject.asObservable();
  public filter: string;
  public displayedColumns = ['date', 'invoiceNo', 'title', 'download'];

  constructor(private _auth: AuthService) {}
  ngOnInit() {}
  ngAfterViewInit() {
    const createdItems = this._auth.currentUser.tenant.creditHistory.filter(f => f.type === 'created');
    const invoices = createdItems
      .sort((a, b) => {
        const momentA = moment(a.date);
        const momentB = moment(b.date);
        if (momentA < momentB) {
          return 1;
        }
        if (momentA > momentB) {
          return -1;
        }
        return 0;
      })
      .map(item => ({
        date: moment(item.date).format('LLL'),
        invoiceNo: item.invoice_number,
        title: item.title,
        url: item.invoice_url,
      }));
    if (this.maxDisplay) {
      invoices.splice(this.maxDisplay, invoices.length - this.maxDisplay);
    }
    setTimeout(() => this._dataSourceSubject.next(invoices));
  }
}
