import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Template } from '../../shared/models/template.model';
import { Tenant } from '../../shared/models/tenant.model';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-create-edit-template-dialog',
  templateUrl: './create-edit-template-dialog.component.html',
  styleUrls: ['./create-edit-template-dialog.component.scss'],
})
export class CreateEditTemplateDialogComponent implements OnInit {
  public template: Template;
  public formIsValid: boolean;
  public busy: boolean;
  public error: string;
  constructor(
    public dialogRef: MatDialogRef<CreateEditTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tenant: Tenant; template: Template },
    private _templateService: TemplateService
  ) {
    this.template = this.data.template ? this.data.template : new Template();
  }

  ngOnInit() {}

  async createOrEdit() {
    this.busy = true;
    try {
      let template: Template | Template[];
      if (this.template._id) {
        const { title, description, category } = this.template;
        template = await this._templateService.patch(this.template._id, {
          title,
          description,
          category,
        });
      } else {
        template = await this._templateService.create(this.template);
      }
      this.dialogRef.close(template);
    } catch (e) {
      this.error = e.message;
    } finally {
      this.busy = false;
    }
  }
}
