<div [ngClass]="{'fullscreen': fullscreen}" class="wrapper">
  <div class="mat-elevation-z10 top-button-bar">
    <div class="buttons">
      <button mat-button color="primary" (click)="close()">
        <mat-icon>close</mat-icon>Schießen
      </button>
    </div>
    <div class="spacer"></div>
    <button mat-icon-button (click)="toggleFullscreen()">
      <mat-icon>{{fullscreen ? 'unfold_less' : 'unfold_more'}}</mat-icon>
    </button>
  </div>
  <mat-tab-group color="accent" #tabs>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-label-icon">important_devices</mat-icon>
        Vorlage
      </ng-template>
      <div class="wrapper">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(templateUrl)"></iframe>
      </div>
    </mat-tab>
    <mat-tab label="Bericht">
      <ng-template mat-tab-label>
        <mat-icon class="tab-label-icon">assignment</mat-icon>
        Bericht
      </ng-template>
      <app-template-report [tenant]="tenant" [template]="template" #report class="app-template-report"></app-template-report>
    </mat-tab>
  </mat-tab-group>
</div>
