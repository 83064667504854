import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'swodocDetailNodeFilter',
  pure: false,
})
export class SwodocDetailNodeFilter implements PipeTransform {
  transform(items: any[], filter: {}): any {
    return items.filter(f => f.nodeType_Id !== 'condition' && !f.inactive);
  }
}
