<mat-card class="login-card">
  <mat-card-title>Registrierung</mat-card-title>
  <mat-card-content>
    <form class="login-form" [formGroup]="registerForm" (submit)="register()">
      <mat-form-field hideRequiredMarker="true" appearance="fill">
        <input
          matInput
          placeholder="Vorname"
          name="firstName"
          formControlName="firstName"
          required
        />
      </mat-form-field>
      <mat-form-field hideRequiredMarker="true" appearance="fill">
        <input
          matInput
          placeholder="Name"
          name="lastName"
          formControlName="lastName"
          required
        />
      </mat-form-field>
      <mat-form-field hideRequiredMarker="true" appearance="fill">
        <input
          matInput
          placeholder="E-Mail"
          name="email"
          formControlName="email"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <input
          matInput
          placeholder="Firma (optional)"
          name="organization"
          formControlName="organization"
        />
      </mat-form-field>

      <mat-form-field hideRequiredMarker="true" appearance="fill">
        <input
          matInput
          placeholder="Passwort"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          formControlName="password"
          required
        />
        <mat-icon
          class="far"
          [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          matSuffix
          (click)="showPassword = !showPassword"
        ></mat-icon>
        <mat-error *ngIf="registerForm.hasError('required', ['password'])">
          Bitte Paswort eingeben
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <input
          matInput
          placeholder="Passwort bestätigen"
          [type]="showConfirmPassword ? 'text' : 'password'"
          name="confirmPassword"
          formControlName="confirmPassword"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          class="far"
          [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          matSuffix
          (click)="showConfirmPassword = !showConfirmPassword"
        ></mat-icon>
        <mat-error *ngIf="registerForm.hasError('notSame')">
          Passwörter stimmen nicht überein
        </mat-error>
      </mat-form-field>

      <button type="submit" style="display: none;">hidden submit</button>
    </form>
  </mat-card-content>
  <mat-card-actions class="login-button">
    <div style="width: 100%;">
      <button
        mat-raised-button
        (click)="register()"
        color="primary"
        style="width: 100%;"
        [disabled]="registerForm.invalid || busy"
      >
        Registrieren
      </button>
      <mat-progress-bar mode="indeterminate" *ngIf="busy"></mat-progress-bar>
    </div>
  </mat-card-actions>
</mat-card>
<div class="footer">
  <a href="https://www.swodoc.de/impressum">Impressum</a><span>|</span>
  <a href="https://www.swodoc.de/datenschutz">Datenschutz</a>
</div>
