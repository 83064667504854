import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SwodocNodesModule } from 'src/app/audit/nodes/swodoc-nodes.module';
import { SwodocDetailNodeFilter } from 'src/app/public/pipes/detail-node.filter';
import { AuditConditionService } from 'src/app/public/services/audit-condition.service';
import { CreateAuditService } from 'src/app/public/services/create-audit.service';
import { PublicAttachmentService } from 'src/app/public/services/public-attachment.service';
import { PublicAuditService } from 'src/app/public/services/public-audit.service';
import { PublicFeathersService } from 'src/app/public/services/public-feathers.service';
import { PublicTemplateService } from 'src/app/public/services/public-template.service';
import { CompletedComponent } from './completed/completed.component';
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PublicComponent } from './public.component';

const publicRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: 'p/notFound', component: NotFoundComponent },
  { path: 'p/error', component: ErrorComponent },
  { path: 'p/completed', component: CompletedComponent },
  { path: 'p/:id', component: PublicComponent },
]);

@NgModule({
  declarations: [
    PublicComponent,
    SwodocDetailNodeFilter,
    NotFoundComponent,
    CompletedComponent,
    ErrorComponent,
  ],
  imports: [
    publicRouting,
    CommonModule,
    SwodocNodesModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
  ],
  providers: [
    PublicFeathersService,
    PublicTemplateService,
    PublicAuditService,
    PublicAttachmentService,
    CreateAuditService,
    AuditConditionService,
  ],
})
export class PublicModule {}
