<h1 mat-dialog-title>
  Daten auswählen

</h1>
<mat-form-field class="full-width" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <input type="search" matInput placeholder="Suche ..." />
</mat-form-field>
<div mat-dialog-content>
  <mat-spinner *ngIf="busy"></mat-spinner>
  <table mat-table [dataSource]="inventory.data" class="">

    <ng-cotainer *ngFor="let column of columns">
      <ng-container matColumnDef="{{column}}">
        <th mat-header-cell *matHeaderCellDef> {{upperFirst(column)}} </th>
        <td mat-cell *matCellDef="let element" (click)="select(element)" style="cursor: pointer;padding-right: 5px;">
          {{element[column]}}
        </td>
      </ng-container>
    </ng-cotainer>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>