import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

import { SwodocNodeInventoryModalComponent } from './modal/swodoc-node-inventory.modal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-inventory',
  templateUrl: 'swodoc-node-inventory.html',
  styleUrls: ['swodoc-node-inventory.scss'],
})
export class SwodocNodeInventoryComponent {
  @Input()
  node: any;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  public get value(): string {
    if (
      this.node.responses &&
      this.node.responses.length > 0 &&
      this.node.responses[0].value
    ) {
      return this.node.responses[0].value;
    }
  }

  public set value(value: string) {
    this.node.responses = this.node.responses || [];
    this.node.responses[0] = { value };
    this.changed.emit(this.node);
  }

  public openModal() {
    const selectables = cloneDeep(this.node.selectables);
    const modal = this.dialog.open(SwodocNodeInventoryModalComponent, {
      data: {
        inventoryId: selectables[0],
        columns: selectables[1],
        selectValue: selectables[2],
      },
    });
    modal.afterClosed().subscribe((modalValues: any) => {
      if (!modalValues) {
        return;
      }
      this.node.responses = [
        { value: modalValues.value, selectedItem: modalValues.selectedItem },
      ];
      this.changed.emit(this.node);
    });
  }
}
