<app-detail-drawer #drawer [fixedTopGap]="0">
  <div body>
    <div class="button-wrapper">
      <button
        mat-button
        (click)="add()"
        color="primary"
        [disabled]="drawerOpen"
      >
        <mat-icon>add</mat-icon> Vorlage hinzufügen
      </button>
    </div>

    <mat-form-field>
      <input
        matInput
        [(ngModel)]="filter"
        placeholder="Filter..."
        [disabled]="drawerOpen"
      />
    </mat-form-field>

    <app-spinner *ngIf="showSpinner"></app-spinner>

    <app-table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [filter]="filter"
      #table
      (itemSelect)="select($event)"
      [(disableSelection)]="drawerOpen"
    >
      <ng-template appRowColumn="id" let-row>{{ row._id }}</ng-template>
      <ng-template
        appRowColumn="public"
        appColumnHeader=" "
        let-row
        [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 70 }"
        ><a
          [href]="row.shared.url"
          mat-icon-button
          target="_blank"
          *ngIf="row.shared && row.shared.url"
          (click)="$event.stopPropagation()"
          ><mat-icon>public</mat-icon></a
        ></ng-template
      >
      <ng-template appRowColumn="category" let-row>{{
        row.category
      }}</ng-template>
      <ng-template appRowColumn="title" let-row>
        <div [ngClass]="{ draft: row.draft }">
          <div *ngIf="row.draft" class="draft-label">Entwurf</div>
          {{ row.title }}
          <span *ngIf="row.publicId === 'g74duz5'" style="color: cadetblue;"
            >(Covid-19 kostenlos)</span
          >
        </div>
      </ng-template>
      <ng-template
        appRowColumn="updatedAt"
        appColumnHeader="Zuletzt geändert"
        let-row
      >
        {{ row.updatedAt | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm [Uhr]' }}
      </ng-template>
      <ng-template
        appRowColumn="matchReport"
        appColumnHeader=" "
        let-row
        [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 60 }"
      >
        <mat-icon
          *ngIf="row.report"
          class="margin-right primary-color template-list-button"
          (click)="openReport($event, row)"
          >assignment</mat-icon
        >
      </ng-template>
      <ng-template
        appRowColumn="more"
        appColumnHeader=" "
        let-row
        [appColumnStyle]="{ flex: '0 0 auto', 'width.px': 60 }"
      >
        <app-template-more-menu
          [template]="row"
          (update)="update()"
          (deleted)="deleted($event)"
        ></app-template-more-menu>
      </ng-template>
    </app-table>
  </div>
  <div detail class="detail">
    <app-template-detail
      [template]="selectedTemplate"
      [sidenav]="drawer"
      (updated)="update()"
      #detail
    ></app-template-detail>
  </div>
</app-detail-drawer>
