import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditFormulaService } from 'src/app/audit/audit-formula.service';
import { AuditNode } from 'src/app/shared/models/audit-node.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-formula',
  templateUrl: 'swodoc-node-formula.html',
  styleUrls: ['swodoc-node-formula.scss'],
})
export class SwodocNodeFormulaComponent implements OnInit {
  @Input()
  node: AuditNode;
  @Input()
  auditNodes: AuditNode[];
  @Input()
  changer: Observable<void>;
  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  public value: string | number;

  constructor(private _auditFormulaService: AuditFormulaService) {}

  ngOnInit() {
    this.changer.subscribe(() => this.eval());
    this.eval();
  }

  private eval() {
    if (this.node.selectables && this.node.selectables.length > 0) {
      this.value = this._auditFormulaService.evalFormula(this.node.selectables, this.auditNodes);
      this.changed.emit({ node: this.node, value: this.value });
    }
  }
}
