<div class="page">
  <app-header
    *ngIf="!isPublicPage && !(auth.isAuthenticated | async)"
  ></app-header>
  <div class="main">
    <app-navigation
      *ngIf="!hideNav && !isPublicPage && (auth.isAuthenticated | async)"
    ></app-navigation>
    <div class="content">
      <app-mobile-menu-header
        *ngIf="!isPublicPage"
        class="mobile-menu-header"
        (menuClicked)="hideNav = !hideNav"
      ></app-mobile-menu-header>
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <app-footer></app-footer> -->
</div>
