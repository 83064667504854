<mat-card>
  <mat-card-header>
    <div mat-card-avatar><mat-icon>group</mat-icon></div>
    <mat-card-title>Organisation</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="form" [formGroup]="tenantForm">
      <mat-form-field hideRequiredMarker="true" class="full-width">
        <input matInput placeholder="Organisation" name="organisation" formControlName="organisation" required />
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions class="profile-button">
    <button
      mat-stroked-button
      class="full-width"
      (click)="saveTenant.emit()"
      color="primary"
      [disabled]="tenantForm.invalid"
    >
      Speichern
    </button>
  </mat-card-actions>
</mat-card>
