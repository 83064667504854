import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { AdminGuard } from 'src/app/shared/services/admin.guard';
import { SharedModule } from 'src/app/shared/shared.module';
import { TenantService } from 'src/app/tenant/tenant.service';
import { InvoiceModule } from '../invoice/invoice.module';
import { InvoiceTileComponent } from './invoice-tile/invoice-tile.component';
import { SecurityTileComponent } from './security-tile/security-tile.component';
import { TenantTileComponent } from './tenant-tile/tenant-tile.component';
import { TenantComponent } from './tenant.component';
import { UsersTileComponent } from './users-tile/users-tile.component';

const tenantRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: 'tenant', component: TenantComponent, canActivate: [AdminGuard] },
  {
    path: 'tenant/invoices',
    loadChildren: () =>
      import('../invoice/invoice.module').then((m) => m.InvoiceModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'tenant/security/apikey',
    loadChildren: () =>
      import('../apikey/apikey.module').then((m) => m.ApikeyModule),
    canActivate: [AdminGuard],
  },
]);

@NgModule({
  declarations: [
    TenantComponent,
    TenantTileComponent,
    UsersTileComponent,
    SecurityTileComponent,
    InvoiceTileComponent,
  ],
  imports: [
    tenantRouting,
    CommonModule,
    MatCardModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    SharedModule,
    MatListModule,
    InvoiceModule,
  ],
  providers: [TenantService],
})
export class TenantModule {}
