import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TemplateCategory } from 'src/app/shared/models/enums/template-category.enum';

@Component({
  selector: 'app-template-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoryComponent),
      multi: true,
    },
  ],
})
export class CategoryComponent implements OnInit, ControlValueAccessor {
  @ViewChild('select', { static: true })
  select: MatSelect;

  public categories = Object.keys(TemplateCategory)
    .filter(f => !(!isNaN(parseFloat(f)) && isFinite(f as any)))
    .map(key => ({ id: TemplateCategory[key], name: key }));

  constructor() {}

  ngOnInit() {}

  writeValue(obj: any): void {
    this.select.value = obj;
  }
  registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.select.disabled = isDisabled;
  }
}
