import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this._authService.isAuthenticated.pipe(take(1)).subscribe((auth) => {
        if (!auth) {
          this._authService.oauthLogin();
          resolve(false);
          return;
        }
        resolve(this._authService.currentUser.roles.includes('admin'));
      });
    });
  }
}
