<div>
  <h1>
    Zusätzliche Credits kaufen?
  </h1>
  <p>
    Du benötigst mehr Credits oder möchtest mehr mit swodoc machen? Nimm einfach
    Kontakt mit uns auf...
  </p>
  <div class="product-wrapper">
    <!-- <app-buy-credits-item
      [product]="product"
      [fade]="fade"
      [(selected)]="selected"
      *ngFor="let product of products"
    ></app-buy-credits-item> -->
    <img src="assets/credits.svg" />
  </div>

  <div class="button-wrapper">
    <a
      href="mailto:credits@swodoc.de?subject=swodoc Credits"
      mat-button
      mat-raised-button
      color="primary"
    >
      <mat-icon>send</mat-icon>Kontaktiere uns...
    </a>
    <!-- <button
      mat-button
      mat-stroked-button
      color="primary"
      [disabled]="!selected"
      (click)="buy()"
    >
      Weiter
    </button> -->
  </div>
</div>
