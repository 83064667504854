<app-detail-drawer #drawer>
  <div body>

    <div class="button-wrapper">
      <button mat-button (click)="add()" color="primary" [disabled]="drawerOpen">
        <mat-icon>add</mat-icon> Gruppe hinzufügen
      </button>
    </div>

    <mat-form-field>
      <input matInput [(ngModel)]="filter" placeholder="Filter..." [disabled]="drawerOpen">
    </mat-form-field>

    <app-spinner *ngIf="showSpinner"></app-spinner>

    <app-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" #table [filter]="filter" (itemSelect)="select($event)"
      [(disableSelection)]="drawerOpen">
      <ng-template appRowColumn="id" let-row>{{row._id}}</ng-template>
      <ng-template appRowColumn="label" let-row>{{row.label}}</ng-template>
    </app-table>

  </div>
  <div detail>
    <app-group-detail [group]="selectedGroup" [tenant]="tenant" [sidenav]="drawer" (updated)="updated($event)"></app-group-detail>
  </div>
</app-detail-drawer>
