<div class="page">
  <div
    class="mat-elevation-z4"
    [style.backgroundImage]="backgroundImage"
    [ngClass]="{
      'top-pflegebox':
        template?.category === 99 || template?.category === 'phx',
      top: template?.category !== 99
    }"
  >
    <ng-container *ngIf="template?.category !== 99">
      <div class="frosted"></div>
      <div class="title">{{ template?.title }}</div>
    </ng-container>
  </div>
  <div class="content">
    <div class="space"></div>
    <div class="main">
      <mat-accordion #accordion="matAccordion" multi>
        <mat-expansion-panel *ngFor="let currentSection of audit?.auditNodes">
          <mat-expansion-panel-header>
            <mat-panel-title> {{ currentSection.title }} </mat-panel-title>
            <mat-panel-description>
              {{ currentSection.description }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            *ngFor="
              let node of currentSection.children | swodocDetailNodeFilter
            "
          >
            <div [ngSwitch]="node.nodeType_Id" class="flex-wrapper">
              <div
                class="mandatory-spacer"
                [ngClass]="{
                  mandatory: node.mandatory,
                  'mandatory-filled': node.mandatory && isMandatoryFilled(node)
                }"
              ></div>

              <div class="node-wrapper">
                <swodoc-node-text
                  *ngSwitchCase="'text'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-text>

                <swodoc-node-date
                  *ngSwitchCase="'date'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-date>

                <swodoc-node-question
                  *ngSwitchCase="'question'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-question>

                <swodoc-node-textarea
                  *ngSwitchCase="'textarea'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-textarea>

                <swodoc-node-information
                  *ngSwitchCase="'information'"
                  [node]="node"
                ></swodoc-node-information>

                <swodoc-node-number
                  *ngSwitchCase="'number'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-number>

                <swodoc-node-checkbox
                  *ngSwitchCase="'checkbox'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-checkbox>

                <swodoc-node-formula
                  *ngSwitchCase="'formula'"
                  [node]="node"
                  [auditNodes]="audit.auditNodes"
                  [changer]="formulaInputChanged$"
                  (changed)="formulaValueChanged($event)"
                ></swodoc-node-formula>

                <swodoc-node-multiplechoice
                  *ngSwitchCase="'multiplechoice'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-multiplechoice>

                <swodoc-node-dropdown
                  *ngSwitchCase="'dropdown'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-dropdown>

                <swodoc-node-picture
                  *ngSwitchCase="'picture'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-picture>

                <swodoc-node-inventory
                  *ngSwitchCase="'inventory'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                ></swodoc-node-inventory>

                <swodoc-node-signature
                  *ngSwitchCase="'signature'"
                  [node]="node"
                  (changed)="nodeUpdate($event)"
                  (busy)="toggleBusy($event)"
                  (imageAdded)="enqueueImage($event)"
                ></swodoc-node-signature>
                <!--
                  <swodoc-node-image
                    *ngSwitchCase="'image'"
                    [node]="node"
                    (changed)="nodeUpdate($event)"
                    (busy)="toggleBusy($event)"
                    [sectionIndex]="currentSectionIndex"
                    [nodeIndex]="audit.auditNodes[currentSectionIndex].children.indexOf(node)"
                    [auditId]="audit._id"
                  ></swodoc-node-image>

                  <swodoc-node-location
                    *ngSwitchCase="'location'"
                    [node]="node"
                    (changed)="nodeUpdate($event)"
                  ></swodoc-node-location>
                -->
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div *ngIf="allMandatoryNodesFilled() === false" class="mandatory-text">
        Bitte füllen Sie alle markierten Pflichtfelder aus.
      </div>
      <div class="send-button">
        <button
          mat-stroked-button
          color="primary"
          (click)="send()"
          [disabled]="allMandatoryNodesFilled() === false"
        >
          <mat-icon>send</mat-icon>Abschicken
        </button>
      </div>
    </div>
    <div class="space"></div>
  </div>
  <div class="footer">
    <div>
      <div>powered by <a href="https://www.swodoc.de">swodoc</a></div>
      <div>
        <a href="https://www.swodoc.de/impressum">Impressum</a> |
        <a href="https://www.swodoc.de/datenschutz">Datenschutz</a>
      </div>
    </div>
  </div>
</div>
