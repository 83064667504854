import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatTableModule } from '@angular/material/table';
import { SwodocNodeInventoryModalComponent } from './swodoc-node-inventory.modal';

@NgModule({
  declarations: [SwodocNodeInventoryModalComponent],
  entryComponents: [SwodocNodeInventoryModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
  ],
})
export class SwodocNodeInventoryModalModule {}
