<h1 mat-dialog-title>Vorlage Teilen</h1>
<mat-dialog-content
  style="min-height: 50px; padding-top: 10px; padding-bottom: 10px;"
>
  <mat-slide-toggle [(ngModel)]="isShared"
    >Vorlage öffentlich teilen</mat-slide-toggle
  >
  <div *ngIf="isShared" class="wrapper">
    <p>Die veröffentlich der Vorlage automatisch wieder aufheben?</p>
    <mat-slide-toggle [(ngModel)]="hasMaxDate">Nach Datum</mat-slide-toggle>
    <mat-form-field *ngIf="hasMaxDate">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Datum"
        [(ngModel)]="template.shared.maxDate"
        [min]="minMaxDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="hasMaxCount">Nach Anzahl</mat-slide-toggle>
    <mat-form-field *ngIf="hasMaxCount">
      <input
        matInput
        type="number"
        placeholder="Anzahl"
        [(ngModel)]="template.shared.maxCount"
      />
    </mat-form-field>
    <p>Öffentliche Url:</p>
    <div style="display: flex;">
      <div class="url">{{ template?.shared?.url }}</div>
      <button mat-icon-button [appCopyClipboard]="template.shared.url">
        <mat-icon>file_copy</mat-icon>
      </button>
    </div>
    <div *ngIf="qrcode" class="centered">
      <img [src]="qrcode" />
    </div>
  </div>
  <div *ngIf="template.publicId === 'g74duz5'" class="centered">
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="generateCustomerRegistration()"
        [disabled]="generatingPdf"
      >
        <mat-icon>get_app</mat-icon> Gästeregistrierung herunterladen
      </button>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="generatingPdf"
      ></mat-progress-bar>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="accent" (click)="dialogRef.close()">
    <mat-icon>cancel</mat-icon>Abbrechen
  </button>
  <button mat-button color="primary" (click)="save()">
    <mat-icon>save</mat-icon> Speichern
  </button>
</mat-dialog-actions>
