import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from 'src/app/inventory/inventory.service';
import { CreateAuditService } from 'src/app/public/services/create-audit.service';
import { AuditReportService } from 'src/app/shared/services/audit-report.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { TreeArray } from 'src/app/shared/tree-array';

import { Template } from '../../../shared/models/template.model';
import { Tenant } from '../../../shared/models/tenant.model';

@Component({
  selector: 'app-template-report',
  templateUrl: './template-report.component.html',
  styleUrls: ['./template-report.component.scss'],
})
export class TemplateReportComponent implements OnInit, OnChanges {
  @Input()
  tenant: Tenant;
  @Input()
  template: Template;
  showSpinner: boolean;
  @ViewChild('designer', { static: true })
  private _designer: any;
  public designer: Stimulsoft.Designer.StiDesigner;
  private _designerRendered: boolean;
  constructor(
    private _reportService: ReportService,
    private _createAudit: CreateAuditService,
    private _auditReport: AuditReportService,
    private inventoryService: InventoryService
  ) {}

  ngOnInit() {
    Stimulsoft.Base.StiLicense.key =
      '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkqXFiOA7oVvfvDQu7RztEmcg4okZVB9DX/nJvqInc7eEQ3yw' +
      'eB5ZYf3V+Tz6Ge9v/G81+IauZVt0ToPx04KUoUMXGXaBxoV8+BT6kNhMzVJZgPcLk3/f/+0i4AjKKTwUHQZeIMMCqS' +
      'NveYk2Kyw+iNGxw0RiVZGQOmq8m5ybZ+wHMQGyHSJPwp+V4FFY5n6m9M4XbeBsvDcPMy+wn+x6Z32194Ydl9gaw4be' +
      'g45uNKkPVGMfqLxNnS5LLOyyrjZlZFNCwhLN8eU3xublBZqFc43blV5GmqXiqsk5WtM4IVFv0SPHJqKVeFCU2mpeRP' +
      'oPQjxDFRsASxSAQps10NTzjP4RgHp0Kwf6R2Bclhh/YFuFmyv0CvB/12hLwNxrCXN14DwexqefASse1mNsGr+6Ujv5' +
      'jtaGd3sRx2Bsx3y67pGB4fWem0fO53txuCDqoipm+id4yHqYcCjATBSGwuFVj+AQM40XvmqyL17n63IZN8Zd5v9Yh5' +
      'RXAbuD+Z9DIYgc6PBwlBpyQxw9010LisE+Lz';
    const options = new Stimulsoft.Designer.StiDesignerOptions();
    options.appearance.fullScreenMode = true;
    options.viewerOptions.appearance.reportDisplayMode = 2;
    this.designer = new Stimulsoft.Designer.StiDesigner(
      options,
      'StiDesigner',
      false
    );
    this.designer.onSaveReport = function (event) {
      const jsonStr = event.report.saveToJsonString();
      if ((this.template as any).report) {
        this._reportService.patch(this.template._id, { report: jsonStr });
      } else {
        this._reportService.create({ _id: this.template._id, report: jsonStr });
        (this.template as any).report = true;
      }
    }.bind(this);
  }

  async ngOnChanges() {
    if (this.template) {
      this.designer.report = undefined;
      // this.showSpinner = true;
      const report = new Stimulsoft.Report.StiReport();
      if ((this.template as any).report) {
        const reportEntity = await this._reportService.get(this.template._id);
        if (reportEntity) {
          report.load(reportEntity.report);
        }
      }
      const data = await this.prepareData();
      const dataSet = new Stimulsoft.System.Data.DataSet('swodoc');
      dataSet.readJson(data);
      report.regData(dataSet.dataSetName, '', dataSet);
      report.dictionary.synchronize();
      this.designer.report = report;
      // this.showSpinner = false;
    }
  }

  private async prepareData() {
    const audit = await this._createAudit.createAudit(this.template);
    const images = TreeArray.filterRec(
      audit.auditNodes,
      (f) => f.nodeType_Id === 'signature' || f.nodeType_Id === 'image'
    );
    if (images) {
      images.map((i: any) => {
        i.responses = i.responses || [];
        i.responses[0] = { data: '' };
      });
    }
    const inventory = TreeArray.filterRec(
      audit.auditNodes,
      (f) => f.nodeType_Id === 'inventory'
    );
    if (inventory) {
      await Promise.all(
        inventory.map(async (m: any) => {
          m.responses = m.responses || [];
          const value = {};
          if (m.selectables && m.selectables.length) {
            if (m.selectables[3]) {
              for (const col of m.selectables[3]) {
                value[col] = '';
              }
            }
          }
          m.responses[0] = { selectedItem: value };
        })
      );
    }
    return this._auditReport.prepareDataForReport(audit);
  }

  async renderDesigner() {
    if (this._designerRendered) {
      return;
    }
    this.showSpinner = true;
    this.designer.renderHtml(this._designer.nativeElement);
    this._designerRendered = true;
    this.showSpinner = false;
  }
}
