<mat-form-field appearance="standard" [hintLabel]="node.description">
  <mat-label>{{ node.title }}</mat-label>
  <input
    matInput
    type="text"
    [(ngModel)]="text"
    [name]="node.title"
    [required]="node.mandatory"
    [autocomplete]="autocomplete"
  />
</mat-form-field>
