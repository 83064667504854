import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuditService } from 'src/app/audit/audit.service';
import { TableComponent } from 'src/app/shared/layout/table/table.component';
import { Template } from 'src/app/shared/models/template.model';
import { TemplateService } from 'src/app/template/template.service';

@Component({
  selector: 'app-export-list',
  templateUrl: './export-list.component.html',
  styleUrls: ['./export-list.component.scss'],
})
export class ExportListComponent implements OnInit {
  @ViewChild('table', { static: true })
  table: TableComponent;

  public displayedColumns = ['schedule', 'title', 'count'];

  private _dataSourceSubject = new Subject<Template[]>();
  public dataSource = this._dataSourceSubject.asObservable();

  public selectedTemplate: Template;

  public showSpinner: boolean;
  public filter: string;
  public drawerOpen: boolean;
  private _allTemplates: Template[];

  constructor(
    private _templateService: TemplateService,
    private _auditService: AuditService,
    private _router: Router
  ) {}

  private async loadTemplates() {
    this.showSpinner = true;
    this._allTemplates = (await this._templateService.find()) as any;
    this._allTemplates = await Promise.all(
      this._allTemplates.map(async (template) => {
        const count = await this._auditService.count({
          query: { templateId: template._id },
        });
        (template as any).count = count;
        return template;
      })
    );
    this._dataSourceSubject.next(this._allTemplates);
    this.showSpinner = false;
  }

  ngOnInit() {
    this.loadTemplates();
  }

  select(template: Template) {
    this._router.navigate(['export', template._id]);
  }

  update() {
    this._dataSourceSubject.next(undefined);
    this.loadTemplates();
  }
}
