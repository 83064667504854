import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SwodocErrorStateMatcher } from 'src/app/shared/error/swodoc-error-state-matcher';
import { InstallService } from 'src/app/shared/services/install.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public busy = false;
  public showPassword: boolean;
  public showConfirmPassword: boolean;
  public registerForm: FormGroup;
  public matcher = new SwodocErrorStateMatcher();

  constructor(
    private _auth: AuthService,
    private _router: Router,
    public snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _installer: InstallService
  ) {
    this.registerForm = this._fb.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        organization: [''],
        password: [
          '',
          {
            validators: [
              Validators.required,
              /* At least 8 characters in length
              Lowercase letters
              Uppercase letters
              Numbers
              Special characters*/
              // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'),
            ],
            updateOn: 'blur',
          },
        ],
        confirmPassword: [''],
      },
      { validator: this.checkPasswords }
    );
  }

  ngOnInit() {}

  public async register() {
    this.snackBar.dismiss();
    try {
      this.busy = true;
      await this._auth.register(
        this.registerForm.get('firstName').value,
        this.registerForm.get('lastName').value,
        this.registerForm.get('email').value,
        this.registerForm.get('organization').value,
        this.registerForm.get('password').value
      );
      await this._auth.login(
        this.registerForm.get('email').value,
        this.registerForm.get('password').value
      );
      const startTemplatePublicId = this._route.snapshot.queryParams[
        'startTemplate'
      ];
      if (startTemplatePublicId) {
        await this._installer.create({
          publicId: startTemplatePublicId,
          autoShare: true,
        });
      }
      this.busy = false;
      this._router.navigateByUrl(
        this._route.snapshot.queryParams['returnUrl'] || '/'
      );
    } catch (e) {
      this.busy = false;
      this.snackBar.open(e.message, undefined, {
        duration: 3000,
      });
    }
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
