<div class="user-detail">
  <mat-toolbar color="primary" class="mat-elevation-z3">
    <mat-icon>person</mat-icon>
    <h1 *ngIf="user.firstName || user.lastName">{{ user.firstName }} {{ user.lastName }}</h1>
    <h1 *ngIf="!user.firstName && !user.lastName">[ Neu ]</h1>
  </mat-toolbar>
  <div class="mat-elevation-z1 top-button-bar">
    <div class="buttons">
      <button mat-button color="primary" (click)="save()"><mat-icon>save</mat-icon>Speichern</button>
      <button mat-button color="accent" (click)="sidenav.close()"><mat-icon>cancel</mat-icon>Abbrechen</button>
    </div>
    <app-user-more-menu [user]="user" *ngIf="user._id"></app-user-more-menu>
  </div>
  <div class="content-wrapper">
    <div class="content">
      <mat-form-field appearance="standard">
        <mat-label>Vorname</mat-label>
        <input matInput placeholder="Vorname" [(ngModel)]="user.firstName" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Nachname</mat-label>
        <input matInput placeholder="Nachname" [(ngModel)]="user.lastName" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>E-Mail</mat-label>
        <input matInput placeholder="E-Mail" [(ngModel)]="user.email" required [disabled]="user._id" />
      </mat-form-field>
      <mat-form-field appearance="standard" *ngIf="!user._id">
        <mat-label>Password</mat-label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          matInput
          placeholder="Password"
          [(ngModel)]="user.password"
        />
        <mat-icon
          class="far"
          [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          matSuffix
          (click)="showPassword = !showPassword"
        ></mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>
