import { Injectable } from '@angular/core';
import { get as lodashGet } from 'lodash';
import { TreeArray } from 'src/app/shared/tree-array';
import { SwodocLang } from 'swodoc-lang';

@Injectable({ providedIn: 'root' })
export class AuditFormulaService {
  public evalFormula(formulaItems, auditNodes) {
    try {
      const lang = new SwodocLang();
      const formula: string = formulaItems[0].value;
      if (
        formulaItems.length > 1 &&
        formulaItems[0].value &&
        formulaItems[1].value &&
        formulaItems[1].value.length > 0
      ) {
        formulaItems[1].value.forEach(linkedNode => {
          const node = TreeArray.findRec(auditNodes, n => n.templateNodeId === linkedNode._id);
          if (!node) {
            return;
          }
          let value;
          if (node.nodeType_Id === 'formula') {
            value = this.evalFormula(node.selectables, auditNodes);
          } else {
            value = lodashGet(node, linkedNode.path);
            if (Array.isArray(value)) {
              if (node.options && node.options.allowMultiple) {
                const selected = value.filter(f => f.selected);
                value = selected
                  .map(m => (m.value && !isNaN(parseFloat(m.value)) && !isNaN(m.value - 0) ? parseFloat(m.value) : 0))
                  .reduce((acc, val) => acc + val, 0);
              } else {
                const selected = value.filter(f => f.selected);
                if (selected.length > 0) {
                  value =
                    selected[0].value && !isNaN(parseFloat(selected[0].value)) && !isNaN(selected[0].value - 0)
                      ? parseFloat(selected[0].value)
                      : selected[0].value;
                }
              }
            } else {
              if (value !== undefined) {
                if (node.nodeType_Id === 'date') {
                  value = new Date(value).getTime();
                } else if (node.nodeType_Id === 'number') {
                  value = parseFloat(value);
                }
              }
            }
          }
          lang.assign(`[${node.templateNodeId}]`, value);
        });
      }
      return lang.eval(formula);
    } catch (e) {}
  }
}
