import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ProgressSpinnerDialogService {
  private _dialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;

  constructor(private _dialog: MatDialog) {}

  public show() {
    this.showProgressSpinnerUntilExecuted();
  }

  public hide() {
    if (!this._dialogRef) {
      return;
    }
    this._dialogRef.close();
  }

  private showProgressSpinnerUntilExecuted() {
    this._dialogRef = this._dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true,
      backdropClass: 'backdrop',
    });
  }
}
