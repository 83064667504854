import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

@Injectable()
export class AuditConditionService {
  public conditionEval(conditions, val) {
    const selectedFilter = (f) => f.selected;
    let values;
    let selected;
    switch (conditions[0].value) {
      case 'text is':
      case 'value is equal':
        return val === conditions[1].value;
      case 'text is not':
      case 'value is not equal':
        return val !== conditions[1].value;
      case 'value is greater':
        return val > conditions[1].value;
      case 'value is less':
        return val < conditions[1].value;
      case 'value is greater or equal':
        return val >= conditions[1].value;
      case 'value is less or equal':
        return val <= conditions[1].value;
      case 'response selected':
        if (!val) {
          return false;
        }
        return val.filter(selectedFilter).length > 0;
      case 'response not selected':
        if (!val) {
          return true;
        }
        return val.filter(selectedFilter).length === 0;
      case 'response is':
        if (!val) {
          return false;
        }
        if (!Array.isArray(val)) {
          return conditions[1].value.toLowerCase() === val.toLowerCase();
        }
        selected = val.filter(selectedFilter);
        if (selected.length !== 1) {
          return false;
        }
        return (
          conditions[1].value.toLowerCase() === selected[0].value.toLowerCase()
        );
      case 'response is not':
        if (!val) {
          return true;
        }
        if (!Array.isArray(val)) {
          return conditions[1].value.toLowerCase() === val.toLowerCase();
        }
        selected = val.filter(selectedFilter);
        if (selected.length !== 1) {
          return true;
        }
        return (
          conditions[1].value.toLowerCase() !== selected[0].value.toLowerCase()
        );
      case 'response contains':
        if (!val) {
          return false;
        }
        selected = cloneDeep(val.filter((f) => f.selected));
        if (selected.length === 0) {
          return false;
        }
        selected = selected.map((v) => v.value.toLowerCase());
        return selected.includes(conditions[1].value.toLowerCase());
      case 'response not contains':
        if (!val) {
          return true;
        }
        selected = cloneDeep(val.filter((f) => f.selected));
        if (selected.length === 0) {
          return true;
        }
        selected = selected.map((v) => v.value.toLowerCase());
        return !selected.includes(conditions[1].value.toLowerCase());
      case 'response is one of':
        if (!val) {
          return false;
        }
        selected = val.filter(selectedFilter);
        if (selected.length !== 1) {
          return false;
        }
        if (
          !(
            conditions[1].value.startsWith('[') &&
            conditions[1].value.endsWith(']')
          )
        ) {
          return false;
        }
        values = conditions[1].value
          .substring(1, conditions[1].value.length - 1)
          .split(',');
        if (!Array.isArray(values)) {
          return false;
        }
        values = values.map((value) => value.toLowerCase());
        return values.indexOf(selected[0].value.toLowerCase()) > -1;
      case 'response is none of':
        if (!val) {
          return true;
        }
        selected = val.filter(selectedFilter);
        if (selected.length !== 1) {
          return true;
        }
        if (
          !(
            conditions[1].value.startsWith('[') &&
            conditions[1].value.endsWith(']')
          )
        ) {
          return true;
        }
        values = conditions[1].value
          .substring(1, conditions[1].value.length - 1)
          .split(',');
        if (!Array.isArray(values)) {
          return true;
        }
        values = values.map((value) => value.toLowerCase());
        return !(values.indexOf(selected[0].value.toLowerCase()) > -1);
      case 'checkbox is checked':
        if (!val) {
          return false;
        }
        return val.filter(selectedFilter).length > 0;
      case 'checkbox is not checked':
        if (!val) {
          return true;
        }
        return val.filter(selectedFilter).length === 0;
      case 'inventory is':
        if (!val || !conditions || conditions.length < 3) {
          return false;
        }
        return val[conditions[1].value] === conditions[2].value;
      case 'inventory is not':
        if (!val || !conditions || conditions.length < 3) {
          return false;
        }
        return val[conditions[1].value] !== conditions[2].value;
    }
  }
}
