export const environment = {
  production: true,
  apiUrl: 'https://api.v2.swodoc.com',
  publicApiUrl: 'https://public.api.v2.swodoc.com',
  attachmentApiUrl: 'https://attachment.api.v2.swodoc.com/attachment',
  templateBuildUrl: 'https://tb.swodoc.com/#/template',
  publicWebAppUrl: 'https://p.swodoc.com/#/p',
  inventoryApiUrl: 'https://inventory.swodoc.com/data',
  amplitude: {
    apiKey: '895199432f51f9c3278d5d8784afeff2',
  },
  authConfig: {
    issuer: `https://auth.halloalberta.de`,
    redirectUri: window.location.origin,
    clientId: '319a294e-e920-459f-a0e7-0b79e136b9a1',
    responseType: 'code',
    scope: 'openid offline_access',
    clearHashAfterLogin: true,
    showDebugInformation: true,
    logoutUrl:
      'https://auth.halloalberta.de/oauth2/logout?client_id=319a294e-e920-459f-a0e7-0b79e136b9a1',
  },
};
