import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuditService } from 'src/app/audit/audit.service';
import { TableComponent } from 'src/app/shared/layout/table/table.component';
import { Audit } from 'src/app/shared/models/audit.model';
import { Report } from 'src/app/shared/models/report.model';
import { Template } from 'src/app/shared/models/template.model';
import { AuditReportService } from 'src/app/shared/services/audit-report.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { TemplateService } from 'src/app/template/template.service';
import { WorkBook, utils, write } from 'xlsx';
import { ScheduleReportService } from './schedule-report.service';
import { ScheduleDialogComponent } from './schedule.dialog.component';

@Component({
  selector: 'app-export-detail',
  templateUrl: './export-detail.component.html',
  styleUrls: ['./export-detail.component.scss'],
})
export class ExportDetailComponent implements AfterViewInit {
  showSpinner: boolean;
  _allAudits: Audit[];

  @ViewChild('table')
  table: TableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns = [];
  public dataSource;

  private _audits;
  public template: Template;
  public drawerOpen: boolean;
  private _templateId: string;
  public resultLength: number;
  public schedule: any;

  constructor(
    private _templateService: TemplateService,
    private _auditService: AuditService,
    private _reportService: ReportService,
    private _auditReportService: AuditReportService,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _scheduleReportService: ScheduleReportService
  ) {
    this._templateId = this._activatedRoute.snapshot.paramMap.get('id');
  }

  async ngAfterViewInit() {
    this.showSpinner = true;
    await this.loadTemplate();
    if (this.template.scheduledReportId) {
      this.schedule = await this._scheduleReportService.get(
        this.template.scheduledReportId
      );
    }
    this.dataSource = this.paginator.page.pipe(
      startWith({}),
      switchMap(() => {
        this.showSpinner = true;
        return from(
          this.loadAudits(
            this.paginator.pageSize,
            this.paginator.pageIndex * this.paginator.pageSize
          )
        );
      }),
      tap((audits) => {
        this._audits = audits.items;
        this.resultLength = audits.total;
      }),
      map((audits) => {
        this.showSpinner = false;
        return audits.items;
      }),
      catchError((error) => {
        this.showSpinner = false;
        return of([]);
      })
    );
  }

  private async loadTemplate() {
    if (this._templateService.cache.length > 0) {
      this.template = this._templateService.cache.find(
        (f) => f._id === this._templateId
      );
    } else {
      this.template = await this._templateService.get(this._templateId).catch();
    }
    let report: Report;
    try {
      report = (await this._reportService.get(this._templateId)) as Report;
    } catch (e) {}
    if (report) {
      (this.template as any).report = report;
    }
  }
  private async loadAudits(
    limit: number,
    skip: number
  ): Promise<{ total: number; items: Audit[] }> {
    const auditFindResult = await this._auditService.find(
      {
        query: {
          templateId: this._templateId,
          // createdAfter: new Date(0),
          $skip: skip,
          $limit: limit,
          $sort: { timestamp: -1 },
        },
      },
      { returnTotal: true, noCaching: true }
    );
    this._allAudits = auditFindResult.items as any;

    if (this._allAudits.length === 0) {
      this.displayedColumns = [];
      this.showSpinner = false;
      return;
    }

    const audits = (await Promise.all(
      this._allAudits.map(async (_audit) => {
        const preparedAudit =
          await this._auditReportService.prepareDataForExport(_audit);
        (preparedAudit as any).selected = false;
        (preparedAudit as any)._id = _audit._id;
        return preparedAudit;
      })
    )) as Audit[];
    this.displayedColumns = Object.keys(audits[0]).filter(
      (f) => f !== 'selected' && f !== '_id'
    );
    this.displayedColumns.unshift('_export');
    return { total: auditFindResult.total, items: audits };
  }

  public async exportToXLSX() {
    if (!this._audits || !this._audits.length) {
      return;
    }
    this.exportXLSX(
      this._audits.map((audit) => {
        const { _id, selected, ...rest } = audit;
        return rest;
      })
    );
  }

  public async exportSingleXLSX(index: number) {
    const audit = { ...this._audits[index] };
    delete audit.selected;
    delete audit._id;
    this.exportXLSX([audit]);
  }

  public async exportSinglePDF(index: number) {
    const reportEntity = (this.template as any).report;
    if (!reportEntity) {
      return;
    }
    Stimulsoft.Base.StiLicense.key =
      '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkqXFiOA7oVvfvDQu7RztEmcg4okZVB9DX/nJvqInc7eEQ3yw' +
      'eB5ZYf3V+Tz6Ge9v/G81+IauZVt0ToPx04KUoUMXGXaBxoV8+BT6kNhMzVJZgPcLk3/f/+0i4AjKKTwUHQZeIMMCqS' +
      'NveYk2Kyw+iNGxw0RiVZGQOmq8m5ybZ+wHMQGyHSJPwp+V4FFY5n6m9M4XbeBsvDcPMy+wn+x6Z32194Ydl9gaw4be' +
      'g45uNKkPVGMfqLxNnS5LLOyyrjZlZFNCwhLN8eU3xublBZqFc43blV5GmqXiqsk5WtM4IVFv0SPHJqKVeFCU2mpeRP' +
      'oPQjxDFRsASxSAQps10NTzjP4RgHp0Kwf6R2Bclhh/YFuFmyv0CvB/12hLwNxrCXN14DwexqefASse1mNsGr+6Ujv5' +
      'jtaGd3sRx2Bsx3y67pGB4fWem0fO53txuCDqoipm+id4yHqYcCjATBSGwuFVj+AQM40XvmqyL17n63IZN8Zd5v9Yh5' +
      'RXAbuD+Z9DIYgc6PBwlBpyQxw9010LisE+Lz';
    const report = new Stimulsoft.Report.StiReport();
    report.load(reportEntity.report);
    const dataSet = new Stimulsoft.System.Data.DataSet('swodoc');
    const audit = this._allAudits.find(
      (f) => f._id === this._audits[index]._id
    );
    const preparedAudit = await this._auditReportService.prepareDataForReport(
      audit
    );
    dataSet.readJson(preparedAudit);
    report.regData(dataSet.dataSetName, '', dataSet);
    report.dictionary.synchronize();
    report.render();
    const pdfData = report.exportDocument(
      Stimulsoft.Report.StiExportFormat.Pdf
    );

    Object.saveAs(pdfData, `${this.getSubject(audit)}.pdf`, 'application/pdf');
  }

  private getSubject(audit: Audit): string {
    let subject = '';
    try {
      if (audit) {
        subject += moment(audit.createdUtc).format('YYYY-MM-DD');
      }

      if (this.template && this.template.title) {
        subject += ' - ';
        subject += this.template.title;
      }
    } catch (e) {}
    return subject;
  }

  // public selectAll() {
  //   this._dataSourceSubject.next(
  //     this._audits.map((m) => {
  //       m.selected = true;
  //       return m;
  //     })
  //   );
  // }

  private exportXLSX(items) {
    const ws_name = 'Tabelle 1';
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = utils.json_to_sheet(items);
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const wbout = write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        // tslint:disable-next-line:no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${this.template.title} ${moment().format('L')}.xlsx`
    );
  }

  public async scheduleReport() {
    const dialogRef = this._dialog.open(ScheduleDialogComponent, {
      data: { schedule: this.schedule },
    });

    dialogRef
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe(async (result) => {
        if (this.template.scheduledReportId) {
          await this._scheduleReportService.patch(
            this.template.scheduledReportId,
            {
              ...result,
              templateId: this.template._id,
              templateName: this.template.title,
            }
          );
        } else {
          await this._scheduleReportService.create({
            ...result,
            templateId: this.template._id,
            templateName: this.template.title,
          });
          this.template.scheduledReportId = `schedule-report-${this.template._id}`;
        }
        this.schedule = await this._scheduleReportService.get(
          this.template.scheduledReportId
        );
      });
  }
}
