import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swodoc-node-information',
  templateUrl: 'swodoc-node-information.html',
  styleUrls: ['swodoc-node-information.scss'],
})
export class SwodocNodeInformationComponent {
  @Input() node: any;
}
