import { Injectable } from '@angular/core';
import { Tenant } from 'src/app/shared/models/tenant.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { FeathersService } from 'src/app/shared/services/feathers.service';

@Injectable()
export class TenantService extends BaseService<Tenant> {
  constructor(feathers: FeathersService) {
    super('tenant', feathers.app);
  }
}
