import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { SharedModule } from '../shared/shared.module';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { InvoiceComponent } from './invoice.component';

@NgModule({
  declarations: [InvoiceTableComponent, InvoiceComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    InvoiceRoutingModule
  ],
  exports: [InvoiceTableComponent]
})
export class InvoiceModule {}
